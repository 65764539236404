import React from 'react';
import './DataBar.scss';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

// Import components
import StatusIndicator from '../../../../components/StatusIndicator/StatusIndicator';
import DataItem from './DataItem/DataItem';
import CoordinatesBox from './CoordinatesBox/CoordinatesBox';
import useDroneTelemetry from '../../../../hooks/useDroneTelemetry';

export default function DataBar({ selectedDrone }) {
  // TODO: we actually want to use useDroneStatus() here
  const { status, location, hardware, uploadStatus, online } = useDroneTelemetry(selectedDrone.droneId);

  const data = [
    {
      id: 1,
      icon: <FontAwesomeIcon icon={regular('battery-half')} />,
      title: 'Battery',
      data: hardware ? hardware.battery_level : 'Unknown',
      dataUnit: '%',
    },
    // {
    //   id: 2,
    //   icon: <FontAwesomeIcon icon={regular('timer')} />,
    //   title: 'Speed',
    //   data: telemetryData ? (realTimeData.cu ? realTimeData.cu : 'Unknown') : 'Unknown',
    //   dataUnit: 'km/h',
    // },
    {
      id: 3,
      icon: <FontAwesomeIcon icon={regular('compass')} />,
      title: 'Heading',
      data: location ? Number(location.heading).toFixed(1) : 'Unknown',
      dataUnit: 'deg',
    },
    {
      id: 4,
      icon: <FontAwesomeIcon icon={regular('gauge-high')} />,
      title: 'Speed',
      data: location ? Number(location.speed).toFixed(1) : 'Unknown',
      dataUnit: 'm/s',
    },
    // {
    //   id: 5,
    //   icon: <FontAwesomeIcon icon={regular('gauge-high')} />,
    //   title: 'Velocity Y',
    //   data: telemetryData ? telemetryData.velocity[0].north_m_s : 'Unknown',
    //   dataUnit: 'm/s',
    // },
    // {
    //   id: 6,
    //   icon: <FontAwesomeIcon icon={regular('gauge-high')} />,
    //   title: 'Velocity Z',
    //   data: telemetryData ? telemetryData.velocity[0].down_m_s : 'Unknown',
    //   dataUnit: 'm/s',
    // },
    {
      id: 4,
      icon: <FontAwesomeIcon icon={regular('map-pin')} />,
      title: 'Coordinates',
    },
  ];

  return (
    <div className="data-bar">
      <div className="top">
        <div className={`border-left ${online ? 'online' : 'offline'}`} />
        <p className="name">Drone</p>
        <StatusIndicator number={2} status={online ? 'online' : 'offline'} />
        <p className="route">Mission status: {status ? status.mission_state : 'Unknown'}</p>
        <p className="route">
          Images left:{' '}
          {uploadStatus
            ? `${uploadStatus.uploaded_count}/${uploadStatus.left_count + uploadStatus.uploaded_count} total left ${
                uploadStatus.left_count_total
              }`
            : 'Not uploading'}
        </p>
      </div>
      <div className="bottom">
        {data.map((item) => (
          <DataItem key={item.id} icon={item.icon} title={item.title} data={item.data} dataUnit={item.dataUnit} />
        ))}
        <CoordinatesBox
          lat={Number(location?.latitude).toFixed(2)}
          long={Number(location?.longitude).toFixed(2)}
          alt={Number(location?.altitude).toFixed(1)}
        />
      </div>
    </div>
  );
}
