// eslint-disable-next-line import/prefer-default-export
export class Mission {
  constructor(json) {
    this.missionId = json.missionId;
    this.userId = json.userId;
    this.name = json.name;
    this.createdAt = json.createdAt;
    this.waypoints = json.waypoints;
    this.missionApps = json.missionApps;
    this.flightPreset = json.flightPreset;
  }
}
