import React, { createContext, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const GlobalModalContext = createContext(undefined);

export function ModalProvider({ children }) {
  const [isVisible, setIsVisible] = React.useState(false);
  const [currentModal, setCurrentModal] = React.useState(null);

  const showGlobalModal = (content) => {
    //Being deprecated
    setCurrentModal(content);
    setIsVisible(true);
  };

  const hideGlobalModal = () => {
    setIsVisible(false);
  };

  return (
    <GlobalModalContext.Provider value={{ showGlobalModal, hideGlobalModal, isVisible, currentModal }}>
      {children}
      <GlobalModal />
    </GlobalModalContext.Provider>
  );
}

export const useGlobalModal = () => {
  const context = useContext(GlobalModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export function GlobalModal() {
  const { isVisible, currentModal } = useGlobalModal();

  if (!currentModal) return null;

  const { icon: Icon, title, description, position = 'top-right', type = 'info' } = currentModal;

  const positionClasses = {
    'top-right': 'top-2 right-2',
    'top-left': 'top-6 left-6',
    'bottom-right': 'bottom-6 right-6',
    'bottom-left': 'bottom-6 left-6',
  }[position];

  const modalStyles = {
    info: {
      iconBg: 'bg-[#4F46E5]/10',
      iconColor: 'text-[#4F46E5]',
      border: 'border-[#4F46E5]/10'
    },
    error: {
      iconBg: 'bg-red-50',
      iconColor: 'text-red-500',
      border: 'border-red-100'
    }
  }[type];

  const modalVariants = {
    initial: {
      opacity: 0,
      y: position.includes('top') ? -20 : 20,
      scale: 0.95,
    },
    animate: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.2,
        ease: "easeOut"
      }
    },
    exit: {
      opacity: 0,
      y: position.includes('top') ? -20 : 20,
      scale: 0.95,
      transition: {
        duration: 0.2,
        ease: "easeIn"
      }
    }
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={`fixed ${positionClasses} w-[400px] bg-white rounded-xl shadow-lg overflow-hidden
            border ${modalStyles.border} z-50`}
          variants={modalVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <div className="p-6 font-inter">
            <div className="flex flex-col items-center text-center">
              <motion.div
                className={`w-16 h-16 ${modalStyles.iconBg} rounded-full flex items-center justify-center mb-4`}
                initial={{ scale: 0, rotate: -180 }}
                animate={{ scale: 1, rotate: 0 }}
                exit={{ scale: 0, rotate: 180 }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 15
                }}
              >
                <Icon className={`w-8 h-8 ${modalStyles.iconColor}`} />
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ delay: 0.1, duration: 0.2 }}
              >
                <h3 className="text-xl font-bold mb-2">
                  {title}
                </h3>
                <p className="text-gray-500 text-sm">
                  {description}
                </p>
              </motion.div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
