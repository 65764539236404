import React from "react";
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const AnimatedOrderFilter = ({ value, onChange }) => {
  const [isChanging, setIsChanging] = useState(false);

  const handleClick = () => {
    setIsChanging(true);
    const nextValue = value === 'Newest' ? 'Oldest' : 'Newest';
    onChange(nextValue);

    setTimeout(() => {
      setIsChanging(false);
    }, 300);
  };

  return (
    <div className="flex items-center gap-2 text-gray-500">
      <button
        onClick={handleClick}
        className="flex items-center gap-1 text-sm hover:text-gray-700 group"
      >
        <div className="relative overflow-hidden h-5">
          <AnimatePresence mode="wait">
            <motion.span
              key={value}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -20, opacity: 0 }}
              transition={{
                duration: 0.3,
                ease: "easeInOut"
              }}
              className="inline-block font-inter text-fontText"
            >
              {value}
            </motion.span>
          </AnimatePresence>
        </div>
        <motion.div
          animate={{ y: isChanging ? 4 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <AnimatePresence mode="wait">
            {value === 'Newest' ? (
              <motion.div
                key="up"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }}
              >
                <ChevronUp
                  className="w-4 h-4 transition-transform duration-300 ease-in-out transform group-hover:translate-y-0.5"
                />
              </motion.div>
            ) : (
              <motion.div
                key="down"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }}
              >
                <ChevronDown
                  className="w-4 h-4 transition-transform duration-300 ease-in-out transform group-hover:translate-y-0.5"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </button>
    </div>
  );
};

export default AnimatedOrderFilter;
