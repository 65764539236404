import React, { useEffect, useRef, useState } from 'react';
import '../shared.scss';
import MissionMap from '../../components/Map/MissionMap';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import GenericService from '../../services/generic.service';
import MapOverlay from './MapOverlay/MapOverlay';
import ReportLog from '../../components/ReportLog/ReportLog';
import MinimizedReportLog from '../../components/ReportLog/MinimizedReportLog/MinimizedReportLog';
import { ModalProvider } from "../../components/Modal/GlobalModalContext";
import MissionInfoPanel from '../../components/Mission/MissionInfoPanel';

/* eslint-disable */
export const MissionsContext = React.createContext(undefined);

export default function Missions() {
  const initialDraftMissionState = {
    name: '',
    missionApps: [],
    waypoints: [],
    homeWaypoint: null,
    qualitySettings: {
      level: 1, // default
      altitude: 40,    // default medium values
      distanceBetweenPaths: 11.2,
      photoDelayAt4ms: 2.1,
      photoDelayAt3ms: 2.8
    },
    // Advanced settings from inputs
    advancedSettings: {
      height: null,
      speed: null,
      altitude: 40,
      lensWidth: 17.3,      // mm
      lensHeight: 13.0,     // mm
      focalLength: 12.3,    // mm
      imageWidth: 5280,     // pixels
      imageHeight: 3956,    // pixels
      dataCollectionSpeed: 0,  // m/s
      connectionPathSpeed: 8.0,  // m/s
      overlapFrontalPercentage: 80,  // %
      overlapSidePercentage: 80,     // %
      fixedGimbalAngle: null,     // degrees
      backGimbalAngle: 20,         // degrees
      forwardGimbalAngle: 60,      // degrees
      downGimbalAngle: 30,         // degrees
      sideGimbalAngle: 20          // degrees
    },
    // Mission specific settings
    missionSettings: {
      objectOfInterestHeight: 0
    }
  };
  
  // All missions state
  const [allMissions, setAllMissions] = useState([]);

  // new mission being created/mission being updated
  const [draftMission, setDraftMission] = useState(initialDraftMissionState);

  //State that determines intersection of lines and causes notification modal in the top right corner to render
  const [isIntersecting, setIsIntersecting] = useState(false);

  //SnakePath Waypoints
  const [snakePathWaypoints, setSnakePathWaypoints] = useState(null);
  const [shouldCreateSnakePath, setShouldCreateSnakePath] = useState(false);

  // SnakePath props, if not userConfigured
  const [widthFOV, setWidthFOV] = useState(14);
  const [heightFOV, setHeightFOV] = useState(10);
  const [frontalOverlap, setFrontalOverlap] = useState(0.1);

  const [speed, setSpeed] = useState(3);
  const [sideOverlap, setSideOverlap] = useState(0.7);

  const [invalidDistanceSegments, setInvalidDistanceSegments] = useState([]);
  const [complexityLevel, setComplexityLevel] = useState('medium');

  // Mission time and possibility status
  const [missionTimeInMinutes, setMissionTimeInMinutes] = useState(0);
  const [isMissionPossible, setIsMissionPossible] = useState(undefined);

  //Updates the drafted mission, which is actually the current mission (either new one or the selected)
  const updateDraftMission = (updates) => {
    setDraftMission(prev => ({
      ...prev,
      ...updates
    }));
  };

  const clickHandler = (e) => {
    if (draftMission.clickHandler) {
      draftMission.clickHandler(e);
    }
  };

  const [maxWaypointId, setMaxWaypointId] = useState(0);
  const [drones, setDrones] = useState([]);
  const [missions, setMissions] = useState([]);
  const [editMissionId, setEditMissionId] = useState(0);
  const [selectedMissionId, setSelectedMissionId] = useState(0);
  const [selectedWaypoint, setSelectedWaypoint] = useState(0);
  const [missionValidationErrors, setMissionValidationErrors] = useState([]);
  const [waypoints, setWaypoints] = useState([]);
  const [photoArea, setPhotoArea] = useState([]);
  const [popupInfo, setPopupInfo] = useState(null);
  const [lineStrings, setLineStrings] = useState(null);
  const [mapStyle, setMapStyle] = useState(localStorage.getItem('mapStyle') ?? 'streets-v11');
  const [currentAltitude, setCurrentAltitude] = useState(0);
  const [showAltitudeInput, setShowAltitudeInput] = useState(false);
  const [apps, setApps] = useState([]);
  const [favoriteApps, setFavoriteApps] = useState([]);
  const [selectAppPopup, setSelectAppPopup] = useState(false);
  const [selectedApps, setSelectedApps] = useState([]);
  const [showReportLog, setShowReportLog] = useState(false);
  const [showMinimizedReportLog, setShowMinimizedReport] = useState(false);
  const [isPolygonClosed, setIsPolygonClosed] = useState(false);
  const [shouldGenerateSnakePath, setShouldGenerateSnakePath] = useState(false);
  const [draggedPosition, setDraggedPosition] = useState(null); //TODO: Not being used I think
  const [isDragging, setIsDragging] = useState(false);

  const mapRef = useRef();

  const getMissions = () => {
    GenericService.MissionService.getMissions().then((response) => {
      setMissions(response);
    });
  };

  const getDrones = () => {
    GenericService.DroneService.getCurrentUserDrones().then((response) => {
      setDrones(response);
    });
  };

  const getFavoriteApps = () => {
    GenericService.AppStoreService.GetFavoriteApps().then((response) => {
      setFavoriteApps(response);
    });
  };

  const getApps = () => {
    GenericService.AppStoreService.GetAllApps().then((response) => {
      setApps(response);
    });
  };

  const resetDraftMission = () => {
    setDraftMission({ ...initialDraftMissionState });
    setSelectedMissionId(null);
    setSnakePathWaypoints([]);
    setIsPolygonClosed(false);
    setShouldGenerateSnakePath(false);
    setSnakePathWaypoints(null)
    setInvalidDistanceSegments([]);
    setLineStrings(null);
    setMissionTimeInMinutes(0);
    setIsMissionPossible(undefined);
    setComplexityLevel('medium');
  }

  useEffect(() => {
    localStorage.setItem('mapStyle', mapStyle);
  }, [mapStyle]);


  useEffect(() => {
    getMissions();
    getDrones();
    getFavoriteApps();
    getApps();
    console.log(apps);
  }, []);

  const displayReportLog = () => {
    setShowReportLog(missionValidationErrors.length > 0);
    setShowMinimizedReport(false);
  };

  const displayMinimizedReportLog = () => {
    setShowReportLog(false);
    setShowMinimizedReport(true);
  };

  useEffect(() => {
    displayReportLog();
  }, [missionValidationErrors]);


  return (
    <ModalProvider>
      <MissionsContext.Provider
        value={{
          missions,
          setMissions,
          missionValidationErrors,
          setMissionValidationErrors,
          editMissionId,
          setEditMissionId,
          selectedMissionId,
          setSelectedMissionId,
          selectedWaypoint,
          setSelectedWaypoint,
          waypoints,
          setWaypoints,
          photoArea,
          setPhotoArea,
          maxWaypointId,
          setMaxWaypointId,
          popupInfo,
          setPopupInfo,
          mapStyle,
          setMapStyle,
          currentAltitude,
          setCurrentAltitude,
          showAltitudeInput,
          setShowAltitudeInput,
          showReportLog,
          showMinimizedReportLog,
          apps,
          favoriteApps,
          selectAppPopup,
          setSelectAppPopup,
          selectedApps,
          setSelectedApps,
          setIsPolygonClosed,
          shouldGenerateSnakePath,
          setShouldGenerateSnakePath,
          snakePathWaypoints,
          setSnakePathWaypoints,
          setDraggedPosition,
          draggedPosition,
          isDragging,
          setIsDragging,
          setLineStrings,
          updateDraftMission,
          draftMission,
          clickHandler,
          setShouldCreateSnakePath,
          isIntersecting,
          setIsIntersecting,
          complexityLevel,
          setComplexityLevel,
          heightFOV,
          widthFOV,
          frontalOverlap,
          sideOverlap,
          speed,
          isPolygonClosed,
          shouldCreateSnakePath,
          invalidDistanceSegments,
          setInvalidDistanceSegments,
          allMissions,
          setAllMissions,
          setDraftMission,
          resetDraftMission,
          missionTimeInMinutes,
          setMissionTimeInMinutes,
          isMissionPossible,
          setIsMissionPossible
        }}
      >
        <PageWrapper>
          <div
            style={{
              height: '100vh',
              width: '100%',
              filter: showReportLog ? 'blur(30px)' : 'none',
              pointerEvents: showReportLog ? 'none' : 'auto',
            }}
          >
            <MissionMap
              waypoints={draftMission.waypoints}
              drones={drones}
              mapRef={mapRef}
              lineStrings={lineStrings}
              mapStyle={mapStyle}
              photoArea={photoArea}
              editable={true}
              selectedWaypoint={selectedWaypoint}
              setSelectedWaypoint={setSelectedWaypoint}
              homeWaypoint={draftMission.homeWaypoint}
              isPolygonClosed={isPolygonClosed}
              setShouldGenerateSnakePath={setShouldGenerateSnakePath}
              setSnakePathWaypoints={setSnakePathWaypoints}
              snakePathWaypoints={snakePathWaypoints}
              setDraggedPosition={setDraggedPosition}
              draggedPosition={draggedPosition}
              setLineStrings={setLineStrings}
              updateDraftMission={updateDraftMission}
              draftMission={draftMission}
              isIntersecting={isIntersecting}
              setIsIntersecting={setIsIntersecting}
              shouldCreateSnakePath={shouldCreateSnakePath}
              invalidDistanceSegments={invalidDistanceSegments}
              setInvalidDistanceSegments={setInvalidDistanceSegments}
              clickHandler={clickHandler}
            />
          </div>

          <MapOverlay />

          {/* Displaying Report Logs on Mission Validation */}
          <div>
            {showReportLog && (
              <div className="map-overlay-report-log-center">
                <ReportLog
                  validationErrors={missionValidationErrors}
                  showEditMissionButton
                  displayMinimizedReportLog={displayMinimizedReportLog}
                />
              </div>
            )}
            {showMinimizedReportLog && (
              <div className="map-overlay-report-log-top-right">
                <MinimizedReportLog errors={missionValidationErrors} />
              </div>
            )}
          </div>
          
          {/* Mission status info panel */}
          <MissionInfoPanel />
        </PageWrapper>
      </MissionsContext.Provider>
    </ModalProvider>
  );
}
