import React, { useEffect } from 'react';
import './AppDetails.scss';
import '../../shared.scss';
import { useLocation } from 'react-router-dom';
// import GenericService from '../../../services/generic.service';

function AppDetailPage() {
  const location = useLocation();
  const { app } = location.state;

  const getBackToAppstore = () => {
    setTimeout(() => {
      window.location.href = '/appstore';
    }, 10);
  };

  // const addFavoriteApps = async () => {
  //   await GenericService.AppStoreService.AddFavoriteApp(app.id);
  //   getBackToAppstore();
  // };

  // const deleteFavoriteApps = async () => {
  //   await GenericService.AppStoreService.DeleteFavoriteApp(app.id);
  //   getBackToAppstore();
  // };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="app-store-details p-6 bg-gray-100 min-h-screen">
      <button
        type="button"
        className="bg-transparent text-gray-700 font-semibold py-2 px-4 border border-gray-700 rounded-full"
        onClick={getBackToAppstore}
      >
        &lt; Back
      </button>
      <div className="mt-8">
        <div className="flex items-center space-x-4">
          <img className="w-16 h-16" src={app.logoUrl} alt="App logo" />
          <h2 className="text-2xl font-bold">{app.name}</h2>
        </div>
        <div className="mt-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="mb-4">
              <p className="text-gray-700">{app.description}</p>
              <a href={app.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                Read more &gt;
              </a>
            </div>
            {/* <div className="mt-6 flex space-x-4">
              <button type="button" className="bg-blue px-3 rounded-xl text-white" onClick={addFavoriteApps}>
                Add to favorites
              </button>
              <button type="button" className="bg-red px-3 rounded-xl text-white" onClick={deleteFavoriteApps}>
                Delete from favorites
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppDetailPage;
