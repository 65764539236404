import React, { useContext, useEffect } from 'react';
import { Source, Layer, Marker } from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import RoutePlanningService from "../../services/routeplanning.service";
import { Waypoint } from "../../classes/Waypoint";
import { MissionsContext } from "../../pages/Missions/Missions";

const SnakePath = ({ isDrag }) => {
  const {
    draftMission,
    homeWaypoint,
    snakePathWaypoints,
    setSnakePathWaypoints,
    shouldCreateSnakePath, // User defined in the flow
    shouldGenerateSnakePath, // Mission plan logic defined, after there is enough waypoints
    widthFOV,
    heightFOV,
    frontalOverlap,
    sideOverlap,
    speed,
    isPolygonClosed,
    isIntersecting,
    invalidDistanceSegments,
    updateDraftMission,
    setMissionTimeInMinutes,
    setIsMissionPossible
  } = useContext(MissionsContext);

  if (isDrag) return null;

  useEffect(() => {
    if (!shouldGenerateSnakePath || isDrag) {
      setSnakePathWaypoints([]);
    }
  }, [shouldCreateSnakePath]);

  useEffect(() => {
    handleIntersectingPolygonWaypoints();
    handleInvalidDistancePolygonWaypoints();

    if (isDrag) {
      setSnakePathWaypoints([]);
      //maybe set the state to false as well?
      return;
    }

    if (shouldCreateSnakePath && isPolygonClosed && draftMission?.waypoints.length >= 4 && !isIntersecting && invalidDistanceSegments.length === 0) {
      handleCreateSnakePath();
    }
  }, [shouldCreateSnakePath, shouldGenerateSnakePath, isPolygonClosed, draftMission.waypoints, draftMission.homeWaypoint, draftMission.qualitySettings, isDrag]);

  const handleIntersectingPolygonWaypoints = () => {
    if (draftMission.homeWaypoint && draftMission.waypoints.length >= 2 && isIntersecting) {
      setSnakePathWaypoints([]);
    }
  }

  const handleInvalidDistancePolygonWaypoints = () => {
    if (invalidDistanceSegments.length > 0) {
      setSnakePathWaypoints([]);
    }
  };

  const handleCreateSnakePath = () => {
    if (!draftMission.qualitySettings) {
      return
    }
    let waypoints = [...draftMission.waypoints]; // To prevent changing draftmission.waypoints

    const snakePathData = {
      waypoints,
      homeWaypoint: draftMission.homeWaypoint,
      properties: {
        objectOfInterestHeight: draftMission.missionSettings.objectOfInterestHeight,
        connectionPathSpeed: draftMission.advancedSettings.connectionPathSpeed,
        connectionMinHighSpeedLength: Number(1),
        quality: draftMission.qualitySettings.level,
        //Advanced settings
        height: draftMission.advancedSettings.height,
        speed: draftMission.advancedSettings.speed,
        altitude: draftMission.advancedSettings.altitude,
        lensWidth: draftMission.advancedSettings.lensWidth,
        lensHeight: draftMission.advancedSettings.lensHeight,
        focalLength: draftMission.advancedSettings.focalLength,
        imageWidth: draftMission.advancedSettings.imageWidth,
        imageHeight: draftMission.advancedSettings.imageHeight,
        dataCollectionSpeed: draftMission.advancedSettings.dataCollectionSpeed,
        connectionPathSpeed: draftMission.advancedSettings.connectionPathSpeed,
        overlapFrontalPercentage: draftMission.advancedSettings.overlapFrontalPercentage,
        overlapSidePercentage: draftMission.advancedSettings.overlapSidePercentage,
        forwardGimbalAngle: draftMission.advancedSettings.forwardGimbalAngle,
        sideGimbalAngle: draftMission.advancedSettings.sideGimbalAngle,
        backGimbalAngle: draftMission.advancedSettings.backGimbalAngle,
        downGimbalAngle: draftMission.advancedSettings.downGimbalAngle
      },
    };

    RoutePlanningService.getSnakePath(snakePathData)
      .then((response) => {
        setSnakePathWaypoints(response.data.waypoints.map((wp) => {
          const waypoint = new Waypoint(wp);
          if (waypoint.waypointType === 2) {
            return waypoint;
          } else {
            return null;
          }
        }));

         //TODO Temp fix for updating the height of the waypoints from backend
        const firstSnakePathWaypointResponse = response.data.waypoints.filter(waypoint => waypoint.waypointType === 2)[0];

        if (firstSnakePathWaypointResponse && draftMission.waypoints[0].altitude !== firstSnakePathWaypointResponse.altitude) {
          updateWaypointHeights(firstSnakePathWaypointResponse.altitude);
          
          // Also update the home waypoint altitude to match
          if (draftMission.homeWaypoint) {
            const updatedHomeWaypoint = {
              ...draftMission.homeWaypoint,
              altitude: firstSnakePathWaypointResponse.altitude
            };
            
            updateDraftMission({
              homeWaypoint: updatedHomeWaypoint
            });
          }
        }

        // Update advanced settings from the snake path response
        if (response.data && response.data.properties) {
          const { properties } = response.data;
          updateDraftMission({
            advancedSettings: {
              height: properties.missionAltitude,
              altitude: properties.missionAltitude,
              lensWidth: properties.lensWidth,
              lensHeight: properties.lensHeight,
              focalLength: properties.focalLength,
              imageWidth: properties.imageWidth,
              imageHeight: properties.imageHeight,
              dataCollectionSpeed: properties.dataCollectionSpeed,
              connectionPathSpeed: properties.connectionPathSpeed,
              overlapFrontalPercentage: properties.overlapFrontalPrecentage,
              overlapSidePercentage: properties.overlapSidePrecentage,
              forwardGimbalAngle: properties.forwardGimbalAngle,
              sideGimbalAngle: properties.sideGimbalAngle,
              backGimbalAngle: properties.backGimbalAngle,
              downGimbalAngle: properties.downGimbalAngle,
            }
          });
        }

        // Extract missionTimeInMinutes and isMissionPossible from the response
        const missionTimeInMinutes = response.data.missionTimeInMinutes;
        const isMissionPossible = response.data.isMissionPossible;

        // Set these values in the context
        setMissionTimeInMinutes(missionTimeInMinutes);
        setIsMissionPossible(isMissionPossible);
      })
      .catch((error) => {
        console.error('Error creating snake path:', error);
      });
  };

  const updateWaypointHeights = (waypointHeight) => {
    const updatedWaypoints = draftMission.waypoints.map(wp => ({
      ...wp,
      altitude: waypointHeight
    }));

    updateDraftMission({
      waypoints: updatedWaypoints
    });
  };

  if (!snakePathWaypoints || snakePathWaypoints.length < 3 || !isPolygonClosed) return null;

  const snakePathLineString = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: snakePathWaypoints.filter(Boolean).map((wp) => [wp.longitude, wp.latitude]),
    },
  };

  return (
    <>

      <Source id="snake-path-line" type="geojson" data={snakePathLineString}>
        <Layer
          id="snake-path"
          type="line"
          paint={{
            'line-color': '#4858E0',
            'line-width': 4
          }}
          layout={{
            'line-cap': 'round',
            'line-join': 'round',
          }}
          beforeId="route"
        />
      </Source>
    </>
  );
};

export default SnakePath;
