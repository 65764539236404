import React, { useContext, useEffect, useState } from 'react';
import { MissionsContext } from "../../pages/Missions/Missions";
import { motion } from 'framer-motion';

const containerVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      delayChildren: 0.1
    }
  }
};

const itemVariants = {
  initial: { opacity: 0, y: 20, scale: 0.95 },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: [0.6, 0.01, -0.05, 0.95]
    }
  }
};

export function MissionName({ onBack, onNext }) {
  const {
    draftMission,
    updateDraftMission,
  } = useContext(MissionsContext);

  const handleNameChange = (e) => {
    updateDraftMission({ name: e.target.value });
  };

  return (
    <motion.div
      className="w-full font-inter"
      variants={containerVariants}
      initial="initial"
      animate="animate"
    >
      <motion.div
        className="mb-2 px-1"
        variants={itemVariants}
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.2 }}
      >
        <h2 className="text-sm text-[rgb(123,139,157)]">Mission name</h2>
      </motion.div>

      <motion.div
        className="relative mb-4"
        variants={itemVariants}
        whileHover={{ scale: 1.01 }}
        transition={{ duration: 0.2 }}
      >
        <input
          type="text"
          value={draftMission.name}
          onChange={handleNameChange}
          placeholder="Type here..."
          className="w-full h-14 px-4 py-2 border border-gray-300 font-inter rounded-lg bg-[#fbfbfb] focus:outline-none"
        />
      </motion.div>
    </motion.div>
  );
}
