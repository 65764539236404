import React, { useState, useEffect } from 'react';
import { Home, Trash2 } from 'lucide-react';
import { Popup } from 'react-map-gl';
import { motion } from 'framer-motion';

const MissionButton = ({
  text,
  onClick,
  fontSize = 'text-base',
  radius = 'rounded-lg',
  className = '',
  variant = 'primary'
}) => {
  const baseClasses = 'px-6 py-2 flex items-center justify-center gap-2 transition-all font-bold';

  const variants = {
    primary: 'bg-[#4F46E5] hover:bg-[#4338CA] text-white',
    secondary: 'bg-[#eeeeee] hover:bg-[#e5e5e5] text-gray-900'
  };

  return (
    <motion.button
      onClick={onClick}
      whileTap={{ scale: 0.98 }}
      className={`
        ${baseClasses}
        ${variants[variant]}
        ${fontSize}
        ${radius}
        ${className}
      `}
    >
      {text}
    </motion.button>
  );
};

const WaypointDetailsPopupCard = ({ waypoint, draftMission, updateDraftMission, onClose, isPolygonClosed, setShouldGenerateSnakePath, setIsPopupModified }) => {
  const [formData, setFormData] = useState({
    altitude: waypoint?.altitude ?? '',
    longitude: waypoint?.longitude ?? '',
    latitude: waypoint?.latitude ?? '',
    heading: waypoint?.heading ?? ''
  });

  useEffect(() => {
    if (waypoint) {
      setFormData({
        altitude: waypoint.altitude ?? '',
        longitude: waypoint.longitude ?? '',
        latitude: waypoint.latitude ?? '',
        heading: waypoint.heading ?? ''
      });
    }
  }, [waypoint]);

  const isHomePoint = waypoint?.waypointType === 0;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsPopupModified(true);
    const updatedWaypoint = {
      ...waypoint,
      ...formData
    };

    if (isHomePoint) {
      updateDraftMission({
        homeWaypoint: updatedWaypoint
      });
    } else {
      const updatedWaypoints = draftMission.waypoints.map(w =>
        w.order === waypoint.order ? updatedWaypoint : w
      );
      updateDraftMission({
        waypoints: updatedWaypoints
      });
    }
    onClose();
    setIsPopupModified(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setIsPopupModified(true);
    if (isHomePoint) return;

    const actualWaypoints = draftMission.waypoints.filter(w =>
      !(w.order === draftMission.waypoints.length &&
        w.longitude === draftMission.waypoints[0].longitude &&
        w.latitude === draftMission.waypoints[0].latitude)
    );

    let updatedWaypoints = actualWaypoints
      .filter(w => w.order !== waypoint.order)
      .map((w, idx) => ({
        ...w,
        order: idx + 1
      }));

    if (isPolygonClosed && updatedWaypoints.length > 2) {
      const closingPoint = {
        ...updatedWaypoints[0],
        order: updatedWaypoints.length + 1
      };
      updatedWaypoints.push(closingPoint);
    }

    updateDraftMission({
      waypoints: updatedWaypoints
    });

    if (isPolygonClosed) {
      setShouldGenerateSnakePath(true);
    }

    onClose();
    setIsPopupModified(true);
  };

  return (
    <Popup
      longitude={waypoint.longitude}
      latitude={waypoint.latitude}
      anchor="left"
      offset={[12, 0]}
      onClose={onClose}
      closeOnClick={false}
      className="custom-popup"
      closeButton={false}
    >
      <div className="font-inter">
        <div className="flex items-center justify-between px-2 py-2 w-80">
          <div className="flex items-center gap-2">
            {isHomePoint ? (
              <Home color={"#4138c2"} className="w-5 h-5 text-blue-600" />
            ) : (
              <Home color={"#4138c2"} className="w-5 h-5 text-blue-600" />
            )}
            <span className="text-base font-medium text-[#5E666D] text-left">
              {isHomePoint ? 'Home Point' : `Waypoint ${waypoint?.order || 1}`}
            </span>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            className="text-[#5E666D] hover:text-gray-600 text-xl"
          >
            ×
          </button>
        </div>

        <form onSubmit={handleSave} className="px-4 pb-4 space-y-3">
          <div className="space-y-1">
            <label className="block text-sm text-[#5E666D] text-left">Altitude</label>
            <input
              name="altitude"
              value={formData.altitude}
              onChange={handleChange}
              placeholder="Type here..."
              type="number"
              className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:border-blue-500 text-[#5E666D]"
            />
          </div>

          <div className="space-y-2 pb-2">
            <label className="block text-sm text-[#5E666D] text-left">Longitude, Latitude</label>
            <span className="block text-sm text-left">{formData.longitude}, {formData.latitude}</span>
          </div>

          <MissionButton
            text="Save"
            onClick={handleSave}
            className="w-full mt-4"
          />

          {!isHomePoint && (
            <div
              onClick={handleDelete}
              className="w-full mt-2 flex font-extrabold items-center justify-center gap-2 cursor-pointer text-red-500 hover:opacity-70"
            >
              <Trash2 color={"#d8513f"} className="w-4 h-4" />
              <span className="font-bold font-inter text-[#d8513f] mt-0.5">Delete Waypoint</span>
            </div>
          )}
        </form>
      </div>
    </Popup>
  );
};

export default WaypointDetailsPopupCard;
