import React, { useContext, useEffect, useState } from 'react';

import { useGlobalModal } from "../Modal/GlobalModalContext";
import { AddApps } from "./AddApps";
import { MissionName } from "./MissionName";
import { motion, AnimatePresence } from 'framer-motion';
import Button from "../Button/Button";
import { Planning } from "./Planning";
import { MissionsContext } from "../../pages/Missions/Missions";
import MissionService from "../../services/Missions/mission.service";
import { AlertCircle, ChevronLeft, ChevronRight } from "lucide-react";
import MissionAppService from '../../services/Apps/missionApp.service';
import RoutePlanningService from "../../services/routeplanning.service";

export default function MissionCreateOrUpdate({ onBack }) {
  const [selected, setSelected] = useState("snake");
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const { showGlobalModal, hideGlobalModal } = useGlobalModal();

  const {
    isIntersecting,
    invalidDistanceSegments,
    draftMission,
    snakePathWaypoints,
    selectedMissionId,
    setShouldCreateSnakePath,
  } = useContext(MissionsContext);

  useEffect(() => {
    setShouldCreateSnakePath(true);
  }, []);

  const handleOnSave = async () => {
    setIsLoading(true)

    if (isIntersecting || invalidDistanceSegments.length > 0 || draftMission.waypoints.length < 4) {
      showGlobalModal({
        icon: AlertCircle,
        title: "Fix the polygon.",
        description: "Can`t save polygon while it`s in an invalid state.",
        position: 'top-right',
        type: 'error'
      });
      setIsLoading(false)
      return
    }

    if(draftMission.missionApps.length < 1) {
      showGlobalModal({
        icon: AlertCircle,
        title: "Add at least one app.",
        description: "Can`t save mission without apps.",
        position: 'top-right',
        type: 'error'
      });
      setIsLoading(false)
      return
    }

    const allWaypoints = [draftMission.homeWaypoint, ...draftMission.waypoints];

    const missionRequest = { //za sad
      userId: draftMission.userId,
      name: draftMission.name,
      flightSpeed: draftMission.flightSpeed,
      waypoints: allWaypoints,
      missionApps: draftMission.missionApps,
      advancedSettings: draftMission.advancedSettings
    }

    //Update mission if viewing one, create mission otherwise
    selectedMissionId ? await updateMission() : await createMission(missionRequest)
  }

  const createMission = async (missionRequest) => {
    try {
      const responseMission = await MissionService.createMission(missionRequest);

      if (draftMission.missionApps.length > 0) {
        const responseCreateAppForMission = await MissionAppService.createMissionApp({ missionId: responseMission.data.missionId, appId: draftMission.missionApps[0].id }); //TODO: Should be done in the same createMission request
      }

      if (responseMission.status === 201) {
        window.toast.success('Mission saved');

        handleSaveSnakePath(responseMission.data.missionId);

      }
    } catch (error) {
      console.error("Failed to create mission:", error);
    } finally {
      setIsLoading(false)
    }
  }

  const updateMission = async () => {
    const updateMissionRequest = {
      ...draftMission,
      missionId: selectedMissionId
    }

    try {
      const responseMission = await MissionService.updateMission(updateMissionRequest);
      if (responseMission.status === 200) {
        window.toast.success('Mission updated');

        handleSaveSnakePath(responseMission.data.missionId);
      }
    } catch (error) {
      console.error("Failed to update mission:", error);
    } finally {
      setIsLoading(false)
    }
  }


  const handleSaveSnakePath = (missionId) => {
    let waypoints = [...draftMission.waypoints]; // To prevent changing draftmission.waypoints

    waypoints = waypoints.map(({ order, ...rest }) => ({
      ...rest,
      orderId: order,
    }));

    const snakePathData = {
      missionId,
      waypoints,
      homeWaypoint: draftMission.homeWaypoint,
      properties: {
        objectOfInterestHeight: draftMission.missionSettings.objectOfInterestHeight,
        connectionPathSpeed: draftMission.advancedSettings.connectionPathSpeed,
        minConnectionPathHighSpeedLength: draftMission.advancedSettings.minConnectionPathHighSpeedLength,
        connectionMinHighSpeedLength: Number(1),
        quality: draftMission.qualitySettings.level,
        //Advanced settings
        height: draftMission.advancedSettings.height,
        speed: draftMission.advancedSettings.speed,
        altitude: draftMission.advancedSettings.altitude,
        lensWidth: draftMission.advancedSettings.lensWidth,
        lensHeight: draftMission.advancedSettings.lensHeight,
        focalLength: draftMission.advancedSettings.focalLength,
        imageWidth: draftMission.advancedSettings.imageWidth,
        imageHeight: draftMission.advancedSettings.imageHeight,
        dataCollectionSpeed: draftMission.advancedSettings.dataCollectionSpeed,
        connectionPathSpeed: draftMission.advancedSettings.connectionPathSpeed,
        overlapFrontalPercentage: draftMission.advancedSettings.overlapFrontalPercentage,
        overlapSidePercentage: draftMission.advancedSettings.overlapSidePercentage,
        forwardGimbalAngle: draftMission.advancedSettings.forwardGimbalAngle,
        sideGimbalAngle: draftMission.advancedSettings.sideGimbalAngle,
        backGimbalAngle: draftMission.advancedSettings.backGimbalAngle,
        downGimbalAngle: draftMission.advancedSettings.downGimbalAngle
      },
    };

    RoutePlanningService.saveSnakePath(snakePathData)
      .then((response) => {
        console.log("Snake path saved successfully");
      })
      .catch((error) => {
        console.error('Error creating snake path:', error);
      });
  };

  return (
    <>
      <AnimatePresence>
        {isSidebarVisible && (
          <motion.div 
            className="overflow-y-auto mb-[45px] pr-2 relative"
            initial={{ x: -400, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -400, opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              '--scrollbar-width': '4px',
              '--scrollbar-track': 'transparent',
              '--scrollbar-thumb': '#4138c2',
              scrollbarWidth: 'thin',
              scrollbarColor: '#3933ec transparent',
              '&::-webkit-scrollbar': {
                width: 'var(--scrollbar-width)',
              },
              '&::-webkit-scrollbar-track': {
                background: 'var(--scrollbar-track)',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'linear-gradient(to bottom, rgba(65, 56, 194, 0.8), #4138c2 10%, #4138c2 90%, rgba(65, 56, 194, 0.8))',
                borderRadius: '20px',
              },
            }}
          >
            <MissionName />
            <AddApps />
            <Planning />

            <motion.div
              className="fixed bottom-0 left-[60px] w-[400px] bg-white p-4 border-t border-[#eeeeee]"
              initial={{ y: 110 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <div className="flex gap-3">
                <Button
                  onClick={onBack}
                  text="Back"
                  optionalClass="bg-[#eeeeee] hover:bg-[#e5e5e5] text-gray-900 rounded-2xl flex-1"
                />
                <Button
                  onClick={handleOnSave}
                  text="Save"
                  buttonType={isLoading ? "loading" : ""}
                  isDisabled={isLoading}
                  optionalClass="bg-[#4F46E5] hover:bg-[#4338CA] text-white rounded-2xl flex-1"
                />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
