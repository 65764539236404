import api from './api';

const getSnakePath = (body) =>
  api
    .post(`/route-planning/snake-path`, body)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));


const saveSnakePath = (body) =>
  api
    .post(`/missions/snake-path`, body)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));


const RoutePlanningService = {
  getSnakePath,
  saveSnakePath
};



export default RoutePlanningService;
