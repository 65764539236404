import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import MissionButton from "./MissionButton";

const BackArrow = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19L8 12L15 5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

const MissionLayout = ({
  title = 'Missions',
  showBack = false,
  onBack,
  showNewButton = false,
  children,
  onNewMissionClick
}) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  return (
    <>
      <AnimatePresence>
        {isSidebarVisible && (
          <motion.div
            className="fixed top-0 left-[60px] h-screen w-[400px] bg-white shadow-lg z-50"
            initial={{ x: -400, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -400, opacity: 0 }}
            transition={{ duration: 0.4, ease: "easeOut" }}
          >
            <div className="h-full w-full p-10 flex flex-col">
              <motion.div
                className="w-full"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.3 }}
              >
                <div className="flex items-center gap-2 mb-2">
                  {showBack && (
                    <motion.button
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.3, duration: 0.2 }}
                      onClick={onBack}
                      className="p-1 hover:bg-gray-100 rounded-full transition-colors -ml-2"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <BackArrow />
                    </motion.button>
                  )}
                  <motion.h1
                    className="text-3xl font-semibold text-fontTitle leading-11 flex-1"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 0.3 }}
                  >
                    {title}
                  </motion.h1>
                  {showNewButton && (
                    <motion.div
                      onClick={onNewMissionClick}
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ delay: 0.5, duration: 0.3 }}
                      whileHover={{ scale: 1.05 }}
                    >
                      <MissionButton className="h-10" text="+ New" />
                    </motion.div>
                  )}
                </div>
              </motion.div>

              <motion.div
                className="flex-1 flex flex-col w-full pt-8 pb-8 overflow-hidden"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7, duration: 0.3 }}
              >
                {children}
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.button
        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
        className={`fixed ${isSidebarVisible ? 'left-[420px]' : 'left-[60px]'} top-5 -translate-y-1/2 z-50 p-2 bg-white rounded-full shadow-lg hover:border-gray-300 transition-all duration-300 group`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.8, duration: 0.3 }}
      >
        {isSidebarVisible ? (
          <ChevronLeft className="w-5 h-5 text-[rgb(123,139,157)] group-hover:text-gray-900 transition-colors" />
        ) : (
          <ChevronRight className="w-5 h-5 text-[rgb(123,139,157)] group-hover:text-gray-900 transition-colors" />
        )}
      </motion.button>
    </>
  );
};

export default MissionLayout;
