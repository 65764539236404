import * as React from 'react';
import { useState } from 'react';
import { useControl, Marker } from 'react-map-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

export default function GeocoderControl({
  mapboxAccessToken,
  onLoading = () => {},
  onResults = () => {},
  onResult = () => {},
  onError = () => {},
  marker: markerProps,
  position,
  proximity,
  language,
  zoom,
  flyTo,
}) {
  const [marker, setMarker] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const geocoder = useControl(
    () => {
      const ctrl = new MapboxGeocoder({
        accessToken: mapboxAccessToken,
        marker: false,
      });
      ctrl.on('loading', onLoading);
      ctrl.on('results', onResults);
      ctrl.on('result', (evt) => {
        onResult(evt);

        const { result } = evt;
        const location =
          result && (result.center || (result.geometry?.type === 'Point' && result.geometry.coordinates));
        if (location) {
          setSelectedLocation(location);
          if (markerProps) {
            setMarker(<Marker {...markerProps} longitude={location[0]} latitude={location[1]} />); //eslint-disable-line
          } else {
            setMarker(null);
          }
        } else {
          setSelectedLocation(null);
          setMarker(null);
        }
      });
      ctrl.on('clear', () => {
        setSelectedLocation(null);
        setMarker(null);
      });
      ctrl.on('error', onError);
      return ctrl;
    },
    { position },
  );

  if (geocoder && geocoder.getProximity() !== proximity && proximity !== undefined) {
    geocoder.setProximity(proximity);
  }
  if (geocoder && geocoder.getLanguage() !== language && language !== undefined) {
    geocoder.setLanguage(language);
  }
  if (geocoder && geocoder.getZoom() !== zoom && zoom !== undefined) {
    geocoder.setZoom(zoom);
  }
  if (geocoder && geocoder.getFlyTo() !== flyTo && flyTo !== undefined) {
    geocoder.setFlyTo(flyTo);
  }

  return (
    <>
      {marker}
      {selectedLocation && (
        <Marker longitude={selectedLocation[0]} latitude={selectedLocation[1]}>
          <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color="blue" />
        </Marker>
      )}
    </>
  );
}