import React from 'react';
import { motion } from 'framer-motion';

const AppIcon = ({ logoUrl }) => {
  if (logoUrl) {
    return (
      <div className="w-[70px] h-[70px] rounded-lg bg-gray-100 overflow-hidden">
        <img src={logoUrl} alt="App logo" className="w-full h-full object-contain" />
      </div>
    );
  }

  return (
    <svg width="70" height="70" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="#F3F4F6"/>
      <path d="M14 20H26M20 14V26" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
};

const itemVariants = {
  initial: { opacity: 0, x: -20 },
  animate: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3 }
  }
};

const AppCard = ({ app, isHovered, onMouseEnter, onMouseLeave, isSelected, onClick }) => (
  <motion.div
    variants={itemVariants}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
    whileHover={{ scale: 0.98, transition: { duration: 0.2 } }}
    className={`p-4 rounded-lg flex items-start gap-4 cursor-pointer h-24 transition-colors duration-200 ${
      isSelected ? 'border-2 border-[#3d5af1]' : ''
    }`}
  >
    <motion.div
      className="flex-shrink-0"
      whileHover={{
        rotateY: 5,
        rotateX: 5,
        transition: {
          type: "spring",
          stiffness: 300,
          damping: 10
        }
      }}
    >
      <AppIcon logoUrl={app.logoUrl} />
    </motion.div>
    <div className={`flex-1 min-w-0 transition-colors duration-200`}>
      <h3 className={`font-bold text-lg font-inter leading-tight mb-1 ${
        isSelected ? 'text-indigo-600' : 'text-fontTitle'
      }`}>{app.name}</h3>
      <p className={`text-sm/[12px] font-inter leading-normal line-clamp-2 ${
        isSelected ? 'text-indigo-400' : 'text-gray-500'
      }`}>{app.description}</p>
    </div>
  </motion.div>
);

export default AppCard;
