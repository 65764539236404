import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as turf from '@turf/turf';
import { Source, Layer, Marker } from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import MissionService from '../../../services/Missions/mission.service';
import useDroneTelemetry from '../../../hooks/useDroneTelemetry';

export default function DroneSource({ drone, selectedDrone, onDroneFocusing }) {
  // Drones data
  const [waypoints, setWaypoints] = useState([]);
  const [realTimeData, setRealTimeData] = useState(null);
  const [realTimeRoutes, setRealTimeRoutes] = useState([]);
  // Map data
  const [shortestPathsTurf, setShortestPathsTurf] = useState(null);
  const [livePositionTurf, setLivePositionTurf] = useState(null);
  const [bearingTurf, setBearingTurf] = useState(0);
  const [traversedPathTurf, setTraversedPathTurf] = useState(null);
  const [lastState, setLastState] = useState(-1);
  const [hasZoomedIn, setHasZoomedIn] = useState(false);

  const { status, location, online } = useDroneTelemetry(drone.droneId);

  const fetchMissionWaypoints = async () => {
    try {
      const mission = await MissionService.getMissionByDroneId(drone.droneId);
      if (mission && mission.waypoints) {
        setWaypoints(mission.waypoints.filter(wp => wp.waypointType === 2));
      }
    } catch (error) {
      console.error('Error fetching mission waypoints:', error);
      toast.error('Failed to load mission waypoints');
    }
  };

  useEffect(() => {
    if (status && status.mission_state !== lastState) {
      if (status.mission_state === 3 && lastState !== -1) {
        // started flying
        toast.dismiss();
        toast.success(`Drone ${drone.droneId} has taken off!`);
      } else if (lastState >= 3 && status.mission_state === 0) {
        // stopped flying
        toast.dismiss();
        toast.error(`Drone ${drone.droneId} has stopped flying!`);
      }

      setLastState(status.mission_state);
    }
  }, [status]);

  // Effect to handle mission waypoints based on droneState and isFocused
  useEffect(() => {
    if (drone && selectedDrone && lastState >= 2 && lastState < 5 && selectedDrone.droneId === drone.droneId) {
      fetchMissionWaypoints();
    } else {
      setWaypoints([]);
    }
  }, [lastState]);

  useEffect(() => {
    if (location && online) {
      const { longitude, latitude } = location;

      // Always update the real-time data or live position of the drone when it is connected
      setRealTimeData((currData) => ({ ...currData, longitude, latitude }));

      // Only update the real-time routes when the drone is flying
      if (status && status.mission_state === 3) {
        // 3 = flying
        setRealTimeRoutes((currRoutes) => [...currRoutes, [longitude, latitude]]);
      }
    }
  }, [location]);

  useEffect(() => {
    if (realTimeData) {
      const { longitude, latitude } = realTimeData;
      if (realTimeData !== null) {
        const point = turf.point([longitude, latitude]);
        setLivePositionTurf(point);
      }
    }
  }, [realTimeData]);

  useEffect(() => {
    if (realTimeRoutes) {
      const numOfRoutes = realTimeRoutes.length;

      if (numOfRoutes > 1) {
        // Updating bearing data
        const start = turf.point(realTimeRoutes[numOfRoutes - 2]);
        const end = turf.point(realTimeRoutes[numOfRoutes - 1]);
        const bearing = turf.bearing(start, end); 
        setBearingTurf(bearing);

        // Updating real-time traversed path
        const lineString = turf.lineString(realTimeRoutes);
        setTraversedPathTurf(lineString);
      }
    }
  }, [realTimeRoutes]);

  useEffect(() => {
    if (onDroneFocusing !== null && selectedDrone !== null && selectedDrone.droneId === drone.droneId) {
      if (realTimeData !== null && !hasZoomedIn) {
        setHasZoomedIn(true);
        onDroneFocusing(realTimeData.longitude, realTimeData.latitude, true);
      }
    }
  }, [selectedDrone, realTimeData]);

  useEffect(() => {
    if (waypoints.length >= 2) {
      console.log(waypoints);
      const coordinates = waypoints.filter(wp => wp.waypointType == 2).map((wp) => [wp.longitude, wp.latitude]);
      const lineString = turf.lineString(coordinates);
      setShortestPathsTurf(lineString);
    } else {
      setShortestPathsTurf(null);
    }
  }, [waypoints]);

  return (
    <>
      {/* Markers for waypoints */}
      {/* {waypoints?.length > 0 &&
        waypoints.map((wp, index) => (
          <Marker id={index} key={wp.order} longitude={wp.longitude} latitude={wp.latitude} anchor="bottom">
            <FontAwesomeIcon className="fa-2x map-waypoint" icon={solid('map-marker-alt')} />
          </Marker>
        ))} */}

      {/* Lines showing shortest paths between waypoints */}
      <Source id="shortest-paths" type="geojson" data={shortestPathsTurf}>
        {waypoints.length >= 2 && (
          <Layer
            id="shortest-paths"
            type="line"
            paint={{
              'line-color': '#aec1cf',
              'line-width': 2,
            }}
          />
        )}
      </Source>

      {/* Live real-time position of the drone when it is flying */}
      <Source id={`Drone ${drone.droneId}`} type="geojson" data={livePositionTurf}>
        <Layer
          id={`drone-position-${drone.droneId}`}
          type="symbol"
          layout={{
            'icon-image': 'airport-15',
            'icon-rotate': bearingTurf,
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-size': 1.2,
          }}
        />
      </Source>

      {/* Real-time traversed path of drone when it is flying */}
      <Source id={`traversed-paths-${drone.droneId}`} type="geojson" data={traversedPathTurf}>
        {realTimeRoutes?.length >= 2 && (
          <Layer
            id={`traversed-paths-${drone.droneId}`}
            type="line"
            paint={{
              'line-color': '#3498db',
              'line-width': 3,
            }}
          />
        )}
      </Source>
    </>
  );
}
