import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { AlertTriangle } from 'lucide-react';

const ConfirmationModal = ({ isOpen, title, message, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  const modalVariants = {
    initial: {
      opacity: 0,
      scale: 0.95,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.2,
        ease: "easeOut"
      }
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 0.2,
        ease: "easeIn"
      }
    }
  };

  const overlayVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-black/50 z-[1001]"
            style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', zIndex: 1001 }}
            variants={overlayVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            onClick={onCancel}
          />
          <motion.div
            className="fixed bg-white rounded-xl shadow-lg overflow-hidden z-[1001] w-[400px]"
            style={{ 
              position: 'fixed', 
              left: '50%', 
              top: '50%', 
              marginLeft: '-200px', 
              marginTop: '-150px',
              zIndex: 1001
            }}
            variants={modalVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <div className="p-6 font-inter">
              <div className="flex flex-col items-center text-center">
                <motion.div
                  className="w-16 h-16 bg-amber-50 rounded-full flex items-center justify-center mb-4"
                  initial={{ scale: 0, rotate: -180 }}
                  animate={{ scale: 1, rotate: 0 }}
                  exit={{ scale: 0, rotate: 180 }}
                  transition={{
                    type: "spring",
                    stiffness: 200,
                    damping: 15
                  }}
                >
                  <AlertTriangle className="w-8 h-8 text-amber-500" />
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ delay: 0.1, duration: 0.2 }}
                >
                  <h3 className="text-xl font-bold mb-2">
                    {title}
                  </h3>
                  <p className="text-gray-500 text-sm mb-6">
                    {message}
                  </p>
                  
                  <div className="flex space-x-4 justify-center">
                    <button
                      onClick={onCancel}
                      style={{
                        padding: '8px 16px',
                        backgroundColor: '#f1f5f9',
                        color: '#1e293b',
                        borderRadius: '8px',
                        fontWeight: '500',
                        border: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={onConfirm}
                      style={{
                        padding: '8px 16px',
                        backgroundColor: '#ef4444',
                        color: '#ffffff',
                        borderRadius: '8px',
                        fontWeight: '600',
                        border: 'none',
                        cursor: 'pointer',
                        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </motion.div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default ConfirmationModal; 