import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRocket, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';
import ErrorList from '../ErrorList/ErrorList';
import GenericService from '../../services/generic.service';

export default function DroneAutomaticCheck({
  checkName,
  checkDescription,
  isLoading,
  isSuccessful,
  errors,
  startCallback,
}) {
  const navigate = useNavigate();
  const droneId = localStorage.getItem('droneId');
  const missionId = localStorage.getItem('missionId');

  // Keep the state variables but make them internal only (not exposed to user)
  const [speed] = useState(4);
  const [photoDelayMs] = useState(2000);
  const [gimbalRotation] = useState(20);
  const [gimbalPitch] = useState(60);

  const onCancelClick = () => {
    // GenericService.FlightReportService.sendDroneCommand(droneId, missionId, 'cancel_take_off');
    navigate('/schedule');
  };

  const onStartClick = () => {
    startCallback({
      speed,
      photoDelay: photoDelayMs,
      rotation: gimbalRotation,
      pitch: gimbalPitch,
      liveStream: true,
      allowProcessing: true,
    });
  };

  if (isLoading) {
    return (
      <div className="container max-w-3xl">
        <Loader />
        <h1 className="text-3xl font-bold mb-5 mt-10">{checkName}</h1>
        <p className="text-lg mb-10 color-grey">{checkDescription}</p>
        <Button text="CANCEL" buttonType="cancel" />
      </div>
    );
  }
  return (
    <div className="flex">
      <div className="w-full flex flex-col justify-center">
        <div>
          {isSuccessful ? (
            <div className="container max-w-3xl">
              <h3 className="font-bold text-3xl mb-5">All checks Finished!</h3>
              <p className="text-lg mb-5 color-grey">
                Ready to take off? When you click &quot;GO&quot;, the drone will take off and start the mission.
              </p>
              <div className="flex gap-4">
                <button className={` px-5 py-2 w-1/2 flex items-center justify-center transition-all font-bold text-white text-lg rounded-lg bg-red`} onClick={onCancelClick} type="submit">
                  <FontAwesomeIcon icon={faTimesCircle}  className={'pr-2'}/>
                  Cancel
                </button>
                <button className={` px-5 py-2 w-1/2 flex items-center justify-center transition-all font-bold text-white text-lg rounded-lg bg-green`} onClick={onStartClick} type="submit">
                  <FontAwesomeIcon icon={faRocket}  className={'pr-2'}/>
                  GO
                </button>
              </div>
              <p className="mt-10 text-red text-lg">
                <span className="text-lg text-red font-bold">Attention: </span>Keep a safe distance now!
              </p>
            </div>
          ) : (
            <ErrorList errors={errors} />
          )}
        </div>
      </div>
    </div>
  );
}
