import React from 'react';
import './Logout.scss';
import { useAuth } from 'react-oidc-context';
import ExchangeAuthService from '../../../../services/Authentication/exchange.service';

// SVG Icon
const LogoutIcon = ({ size = 20 }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={size} 
    height={size} 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
    <polyline points="16 17 21 12 16 7"></polyline>
    <line x1="21" y1="12" x2="9" y2="12"></line>
  </svg>
);

export default function Logout() {
  const auth = useAuth();

  const handleLogout = () => {
    ExchangeAuthService.isAvailable().then((success) => {
      if (success) {
        ExchangeAuthService.exchangeLogout().catch((e) => {
          console.error(e);
        });
      }
      // auth.removeUser(); // This will destroy current session, but user will still be able to login again
      auth.signoutRedirect(); // This will log out user from OIDC provider
    });
  };

  return (
    <div className="Logout">
      <div className="logout-icon-wrapper" onClick={handleLogout}>
        <LogoutIcon size={20} />
      </div>
    </div>
  );
}
