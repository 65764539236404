import React from 'react';
import { motion } from 'framer-motion';

const Loader = ({ isLoading = true }) => (
  <div className="flex justify-center items-center h-full">
    <motion.div
      animate={{ rotate: 360 }}
      transition={{
        duration: 1.4,
        repeat: Infinity,
        ease: "linear"
      }}
      style={{
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        border: '3px solid rgba(65, 56, 194, 0.1)',
        borderTopColor: '#4138c2',
        borderLeftColor: '#4138c2',
        borderTopLeftRadius: '50%',
        borderTopRightRadius: '50%',
        borderBottomLeftRadius: '50%',
        borderBottomRightRadius: '50%',
        transform: 'rotate(-45deg)',
        borderRightWidth: '5px',
        borderBottomWidth: '5px',
        borderTopWidth: '5px',
        borderLeftWidth: '5px',
      }}
    />
  </div>
);

export default Loader;
