import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Camera, Gauge, Layers } from 'lucide-react';

export default function AdvancedSettings({ isOpen, setIsOpen, draftMission, updateDraftMission }) {
  return (
    <div className="mt-8">
      <motion.div className="w-full">
        <motion.button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full flex items-center justify-between p-4 text-left bg-white rounded-lg border border-gray-200 hover:border-gray-300 transition-colors duration-200"
        >
          <div className="flex items-center gap-2">
            <div className="bg-gray-50 p-2 rounded-lg">
              <Gauge className="w-5 h-5 text-[rgb(123,139,157)]" />
            </div>
            <div>
              <h2 className="text-sm font-medium text-[rgb(123,139,157)]">Advanced settings</h2>
              <p className="text-xs text-[rgb(123,139,157)]">Camera, speed, overlap and gimbal settings</p>
            </div>
          </div>
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
            className="bg-gray-50 p-2 rounded-lg"
          >
            <ChevronDown className="w-5 h-5 text-[rgb(123,139,157)]" />
          </motion.div>
        </motion.button>

        <AnimatePresence>
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: isOpen ? 'auto' : 0,
              opacity: isOpen ? 1 : 0,
            }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <div className="pt-4 space-y-6">
              {/* Camera Settings Card */}
              <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-100">
                <div className="flex items-center gap-2 mb-4">
                  <Camera className="w-5 h-5 text-black" />
                  <h3 className="text-sm font-medium text-black">Camera Settings</h3>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block">Lens Width (mm)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.lensWidth}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            lensWidth: parseFloat(e.target.value)
                          }
                        });
                      }}
                      placeholder="17.3"
                      className="w-full p-2 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-200 transition-all"
                    />
                  </div>
                  <div>
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block">Lens Height (mm)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.lensHeight}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            lensHeight: parseFloat(e.target.value)
                          }
                        });
                      }}
                      placeholder="13.0"
                      className="w-full p-2 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-200 transition-all"
                    />
                  </div>
                  <div>
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block">Image Width (px)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.imageWidth}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            imageWidth: parseInt(e.target.value)
                          }
                        });
                      }}
                      placeholder="5280"
                      className="w-full p-2 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-200 transition-all"
                    />
                  </div>
                  <div>
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block">Image Height (px)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.imageHeight}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            imageHeight: parseInt(e.target.value)
                          }
                        });
                      }}
                      placeholder="3956"
                      className="w-full p-2 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-200 transition-all"
                    />
                  </div>
                  <div>
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block">Focal Length (mm)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.focalLength}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            focalLength: parseFloat(e.target.value)
                          }
                        });
                      }}
                      placeholder="12.3"
                      className="w-full p-2 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-200 transition-all"
                    />
                  </div>
                </div>
              </div>

              {/* Speed Settings Card */}
              <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-100">
                <div className="flex items-center gap-2 mb-4">
                  <Gauge className="w-5 h-5 text-black" />
                  <h3 className="text-sm font-medium text-black">Speed Settings</h3>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block">Height (meters)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.height}
                      onChange={(e) => {
                        const value = e.target.value === '' ? 40 : parseFloat(e.target.value);
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            height: value
                          }
                        });
                      }}
                      placeholder="40"
                      className="w-full p-2 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-200 transition-all"
                    />
                  </div>
                  <div>
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block">Connection Path Speed (m/s)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.connectionPathSpeed}
                      onChange={(e) => {
                        const value = e.target.value === '' ? 4.0 : parseFloat(e.target.value);
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            connectionPathSpeed: value
                          }
                        });
                      }}
                      placeholder="4.0"
                      className="w-full p-2 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-200 transition-all"
                    />
                  </div>
                  <div>
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block">Data Collection (m/s)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.dataCollectionSpeed}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            dataCollectionSpeed: parseFloat(e.target.value)
                          }
                        });
                      }}
                      placeholder="4.0"
                      className="w-full p-2 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-200 transition-all"
                    />
                  </div>
                  <div>
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block">Connection Path (m/s)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.connectionPathSpeed}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            connectionPathSpeed: parseFloat(e.target.value)
                          }
                        });
                      }}
                      placeholder="8.0"
                      className="w-full p-2 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-200 transition-all"
                    />
                  </div>
                </div>
              </div>

              {/* Overlap Settings Card */}
              <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-100">
                <div className="flex items-center gap-2 mb-4">
                  <Layers className="w-5 h-5 text-[rgb(123,139,157)]" />
                  <h3 className="text-sm font-medium text-[rgb(123,139,157)]">Overlap Settings</h3>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block">Frontal Overlap (%)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.overlapFrontalPercentage}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            overlapFrontalPercentage: parseInt(e.target.value)
                          }
                        });
                      }}
                      placeholder="80"
                      className="w-full p-2 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-200 transition-all"
                    />
                  </div>
                  <div>
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block">Side Overlap (%)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.overlapSidePercentage}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            overlapSidePercentage: parseInt(e.target.value)
                          }
                        });
                      }}
                      placeholder="80"
                      className="w-full p-2 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-200 transition-all"
                    />
                  </div>
                </div>
              </div>

              {/* Gimbal Settings Card */}
              <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-100">
                <div className="flex items-center gap-2 mb-4">
                  <Camera className="w-5 h-5 text-[rgb(123,139,157)]" />
                  <h3 className="text-sm font-medium text-[rgb(123,139,157)]">Gimbal Settings</h3>
                </div>
                <div className="relative w-full h-[300px] flex items-center justify-center">
                  {/* Top Input (Forward) */}
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 z-10">
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block text-center">Forward (°)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.forwardGimbalAngle || 0}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            forwardGimbalAngle: parseFloat(e.target.value) || 0
                          }
                        });
                      }}
                      className="w-20 p-2 text-center rounded-lg bg-[#fbfbfb] text-sm focus:outline-none focus:border-[#6366F1]"
                    />
                  </div>

                  {/* Left Input (Side) */}
                  <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block text-center">Side (°)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.sideGimbalAngle || 0}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            sideGimbalAngle: parseFloat(e.target.value) || 0
                          }
                        });
                      }}
                      className="w-20 p-2 text-center rounded-lg bg-[#fbfbfb] text-sm focus:outline-none focus:border-[#6366F1]"
                    />
                  </div>

                  {/* Center Drone Icon */}
                  <div className="relative w-32 h-32 bg-[#f0f0f0] rounded-full flex items-center justify-center z-0">
                    <div className="w-24 h-24 bg-[#4F46E5] rounded-full flex items-center justify-center">
                      <svg viewBox="0 0 24 24" className="w-16 h-16 text-white" fill="none" stroke="currentColor">
                        {/* Main body */}
                        <rect x="9" y="9" width="6" height="6" rx="1" strokeWidth="1.5" />
                        
                        {/* Propeller arms */}
                        <line x1="6" y1="6" x2="9" y2="9" strokeWidth="1.5" />
                        <line x1="15" y1="9" x2="18" y2="6" strokeWidth="1.5" />
                        <line x1="6" y1="18" x2="9" y2="15" strokeWidth="1.5" />
                        <line x1="15" y1="15" x2="18" y2="18" strokeWidth="1.5" />
                        
                        {/* Propellers */}
                        <circle cx="5" cy="5" r="2" strokeWidth="1.5" />
                        <circle cx="19" cy="5" r="2" strokeWidth="1.5" />
                        <circle cx="5" cy="19" r="2" strokeWidth="1.5" />
                        <circle cx="19" cy="19" r="2" strokeWidth="1.5" />
                        
                        {/* Camera mount and camera */}
                        <rect x="11" y="6" width="2" height="3" rx="0.5" strokeWidth="1.5" />
                        <rect x="10.5" y="4.5" width="3" height="2" rx="0.5" strokeWidth="1.5" />
                        <circle cx="12" cy="5.5" r="0.5" fill="currentColor" />
                      </svg>
                      <div className="absolute w-3 h-3 bg-red-500 rounded-full bottom-2 transform translate-y-1/2"></div>
                    </div>
                  </div>

                  {/* Right Input (Back) */}
                  <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block text-center">Back (°)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.backGimbalAngle || 0}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            backGimbalAngle: parseFloat(e.target.value) || 0
                          }
                        });
                      }}
                      className="w-20 p-2 text-center rounded-lg bg-[#fbfbfb] text-sm focus:outline-none focus:border-[#6366F1]"
                    />
                  </div>

                  {/* Bottom Input (Down) */}
                  <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 z-10">
                    <label className="text-xs text-[rgb(123,139,157)] mb-1 block text-center">Down (°)</label>
                    <input
                      type="number"
                      value={draftMission.advancedSettings.downGimbalAngle || 0}
                      onChange={(e) => {
                        updateDraftMission({
                          advancedSettings: {
                            ...draftMission.advancedSettings,
                            downGimbalAngle: parseFloat(e.target.value) || 0
                          }
                        });
                      }}
                      className="w-20 p-2 text-center rounded-lg bg-[#fbfbfb] text-sm focus:outline-none focus:border-[#6366F1]"
                    />
                  </div>

                  {/* Connection Lines */}
                  <div className="absolute inset-0 pointer-events-none" style={{ zIndex: -1 }}>
                    <svg className="w-full h-full" style={{ position: 'absolute' }}>
                      <line x1="50%" y1="15%" x2="50%" y2="35%" stroke="#cccccc" strokeWidth="1" />
                      <line x1="15%" y1="50%" x2="35%" y2="50%" stroke="#cccccc" strokeWidth="1" />
                      <line x1="65%" y1="50%" x2="85%" y2="50%" stroke="#cccccc" strokeWidth="1" />
                      <line x1="50%" y1="65%" x2="50%" y2="85%" stroke="#cccccc" strokeWidth="1" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </div>
  );
} 