import React, { useEffect, useContext, useState } from 'react';
import './Map.scss';
import mapboxgl from 'mapbox-gl';
import MapGL, {
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
  Marker,
} from 'react-map-gl';
import proj4 from 'proj4';
import GeoHelper from '../../services/geoservice';
import DroneSource from './DashboardMap/DroneSource';
import { AlertCircle, Home, MapPin, Plus } from "lucide-react";
import { motion } from "framer-motion";
import { useGlobalModal } from "../Modal/GlobalModalContext";
import SnakePath from "../Mission/SnakePath";
import WaypointDetailsPopupCard from "../Mission/WaypointDetailsPopupCard";
import GeocoderControl from './GeocoderControl';
import { MissionsContext } from "../../pages/Missions/Missions";

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// Define the EPSG:28992 projection
const RDNew = 'EPSG:28992';
const WGS84 = 'EPSG:4326';

const initialViewState = {
  longitude: 4.55,
  latitude: 52.23,
  zoom: 6.5,
};

export default function MissionMap({
  clickHandler,
  mapRef,
  lineStrings,
  mapStyle,
  waypoints,
  editable,
  drones,
  selectedWaypoint,
  setSelectedWaypoint,
  homeWaypoint,
  isPolygonClosed,
  setShouldGenerateSnakePath,
  updateDraftMission,
  draftMission,
  isIntersecting,
  setIsIntersecting,
  invalidDistanceSegments,
  setInvalidDistanceSegments,
}) {
  const {
    isDragging,
    setIsDragging
  } = useContext(MissionsContext);

  const [geoData, setGeoData] = useState(null);
  const [geoDataNoNatura2000, setGeoDataNoNatura2000] = useState(null);
  const [isPopupModified, setIsPopupModified] = useState(false);

  const pulseAnimation = {
    scale: [1, 1.1, 1],
    opacity: [0.7, 1, 0.7],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut",
    },
  };

  const markerVariants = {
    hover: {
      scale: 1.1,
      transition: { duration: 0.2 },
    },
    initial: {
      scale: 0,
      opacity: 0,
    },
    animate: {
      scale: [1, 1.05, 1],
      opacity: 1,
      transition: {
        scale: {
          repeat: Infinity,
          duration: 2,
          ease: "easeInOut",
        },
      },
    },
  };

  const { showGlobalModal, hideGlobalModal } = useGlobalModal();

  // Function to filter features based on mapStyle
  const filterFeatures = (data, onlyRedZones) => {
    if (!data) return null;

    return {
      ...data,
      features: data.features.filter((feature) => {
        if (onlyRedZones) {
          // Only show features that are 'Verboden' type AND contain 'radio' in sourceTxt
          return (
            feature.properties.localtype === 'Verboden' &&
            feature.properties.sourceTxt.toLowerCase().includes('radio')
          );
        }
        return true; // Show all features when onlyRedZones is false
      }),
    };
  };

  //When mission selected that has waypoints, flyto the waypoints
  useEffect(() => {
    if (waypoints.length === 0) return;

    // Only prevent auto-zoom for new missions without an ID
    if (!draftMission?.id && waypoints.length < 2) return;

    const bounds = new mapboxgl.LngLatBounds();

    waypoints.forEach(({ longitude, latitude }) => {
      bounds.extend([longitude, latitude]);
    });

    mapRef.current.fitBounds(bounds, {
      padding: { top: 100, right: 100, bottom: 100, left: 300 },
      duration: 3000,
      maxZoom: 18
    });
  }, [waypoints, draftMission?.id]);

  // Fetch and reproject GeoJSON data on component mount
  useEffect(() => {
    if (mapStyle !== 'nofly-zones' && mapStyle !== 'nofly-zones-all') return;
    fetch(
      'https://service.pdok.nl/lvnl/drone-no-flyzones/wfs/v1_0?request=GetFeature&service=WFS&version=2.0.0&typeName=drone-no-flyzones:luchtvaartgebieden&outputFormat=application/json'
    )
      .then((response) => response.json())
      .then((data) => {
        const reprojectedData = {
          ...data,
          features: data.features.map((feature) => {
            const { geometry } = feature;
            if (geometry.type === 'Polygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: [geometry.coordinates[0].map((point) => proj4(RDNew, WGS84, point))],
                },
              };
            }
            if (geometry.type === 'MultiPolygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: geometry.coordinates.map((polygon) =>
                    polygon.map((point) => proj4(RDNew, WGS84, point))
                  ),
                },
              };
            }
            return feature;
          }),
        };
        setGeoData(reprojectedData);
      })
      .catch((err) => console.error('Error loading geo data: ', err));

    fetch(
      'https://service.pdok.nl/lvnl/drone-no-flyzones/wfs/v1_0?request=GetFeature&service=WFS&version=2.0.0&typeName=drone-no-flyzones:luchtvaartgebieden_zonder_natura2000&outputFormat=application/json'
    )
      .then((response) => response.json())
      .then((data) => {
        const reprojectedData = {
          ...data,
          features: data.features.map((feature) => {
            const { geometry } = feature;
            if (geometry.type === 'Polygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: [geometry.coordinates[0].map((point) => proj4(RDNew, WGS84, point))],
                },
              };
            }
            if (geometry.type === 'MultiPolygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: geometry.coordinates.map((polygon) =>
                    polygon.map((point) => proj4(RDNew, WGS84, point))
                  ),
                },
              };
            }
            return feature;
          }),
        };
        setGeoDataNoNatura2000(reprojectedData);
      })
      .catch((err) => console.error('Error loading geo data (zonder Natura 2000): ', err));
  }, [mapStyle]);

  const mapboxStyle =
    mapStyle === 'nofly-zones-all' || mapStyle === 'nofly-zones'
      ? 'mapbox://styles/mapbox/streets-v11'
      : `mapbox://styles/mapbox/${mapStyle}`;

  useEffect(() => {
    if (
      mapRef.current &&
      draftMission?.waypoints
    ) {
      if (draftMission?.id && !isDragging && !isPopupModified) {
        const bounds = new mapboxgl.LngLatBounds();
        draftMission.waypoints.forEach(waypoint => {
          bounds.extend([waypoint.longitude, waypoint.latitude]);
        });
        mapRef.current.getMap().fitBounds(bounds, {
          padding: 50,
          duration: 1500,
          maxZoom: 16,
          minZoom: 5
        });
      }
    }
  }, [draftMission]);

  useEffect(() => {
    if (isIntersecting) {
      showGlobalModal({
        icon: AlertCircle,
        title: "Invalid Polygon",
        description: "Lines cannot intersect. Please adjust the waypoint position.",
        position: 'top-right',
        type: 'error'
      });
    }
  }, [isIntersecting, showGlobalModal]);

  useEffect(() => {
    if (!waypoints || waypoints.length < 2) return;

    const filteredWaypoints = waypoints.filter(wp => wp.waypointType === 1);
    const newInvalidSegments = [];

    for (let i = 0; i < filteredWaypoints.length - 1; i++) {
      const distance = GeoHelper.calculateDistance(
        filteredWaypoints[i],
        filteredWaypoints[i + 1]
      );
      if (distance > 2000) {
        newInvalidSegments.push([i, i + 1]);
      }
    }

    if (filteredWaypoints.length > 2) {
      const distance = GeoHelper.calculateDistance(
        filteredWaypoints[filteredWaypoints.length - 1],
        filteredWaypoints[0]
      );
      if (distance > 2000) {
        newInvalidSegments.push([filteredWaypoints.length - 1, 0]);
      }
    }

    setInvalidDistanceSegments(newInvalidSegments);

    if (newInvalidSegments.length > 0) {
      showGlobalModal({
        icon: AlertCircle,
        title: "Invalid Distance",
        description: "Distance between waypoints cannot exceed 2000 meters",
        position: 'top-right',
        type: 'error'
      });
    } else {
      hideGlobalModal();
    }
  }, [waypoints]);

  const handleMidpointClick = (currentWp, nextWp) => {
    const midpoint = GeoHelper.calculateMidpoint(currentWp, nextWp);

    const newWaypoint = {
      latitude: midpoint.latitude,
      longitude: midpoint.longitude,
      altitude: currentWp.altitude,
      heading: 0,
      waypointType: 1,
      order: currentWp.order + 1
    };

    const updatedWaypoints = waypoints.map(wp => ({
      ...wp,
      order: wp.order > currentWp.order ? wp.order + 1 : wp.order
    }));

    updatedWaypoints.push(newWaypoint);
    updatedWaypoints.sort((a, b) => a.order - b.order);

    updateDraftMission({
      waypoints: updatedWaypoints
    });

    setShouldGenerateSnakePath(true);
  };

  const handleDrag = (wp) => (e) => {
    setIsDragging(true);
    setShouldGenerateSnakePath(false);

    const updatedWaypoint = {
      ...wp,
      latitude: e.lngLat.lat,
      longitude: e.lngLat.lng
    };

    const hasIntersection = GeoHelper.checkForIntersections(waypoints, wp, updatedWaypoint);
    if (hasIntersection) {
      setIsIntersecting(true);
    } else {
      setIsIntersecting(false);
      hideGlobalModal();
    }

    if (wp.waypointType === 0) {
      updateDraftMission({
        homeWaypoint: updatedWaypoint
      });

      return;
    }

    let updatedWaypoints = waypoints.map(w => {

      if (w.order === wp.order) {
        return updatedWaypoint;
      }

      if (isPolygonClosed && wp.order === 0 && w.order === waypoints.length - 1) {
        return {
          ...w,
          latitude: updatedWaypoint.latitude,
          longitude: updatedWaypoint.longitude
        };
      }

      return w;
    });

    updateDraftMission({
      waypoints: updatedWaypoints
    });

    if (isPolygonClosed) {
      setShouldGenerateSnakePath(true);
    }
  };

  const handleDragEnd = () => {
    setShouldGenerateSnakePath(true);

    //Timeout needed so that the click event is not triggered after dragging
    setTimeout(() => {
      setIsDragging(false);
    }, 100)
  };

  const handleClick = (e) => {
    if (isDragging) return

    clickHandler(e);
  };

  const markers = () => {
    const allMarkers = [];

    if (homeWaypoint?.latitude && homeWaypoint?.longitude) {
      allMarkers.push(createMarker(homeWaypoint, true));
    }

    if (waypoints?.length > 0) {
      const missionWaypoints = filterMissionWaypoints(waypoints, isPolygonClosed);
      const waypointMarkers = missionWaypoints.map(wp => createMarker(wp));

      allMarkers.push(...waypointMarkers);
    }

    return allMarkers;
  };

  const createMarker = (wp, isHome = false) => (
    <Marker
      key={isHome ? 'home-marker' : `marker-${wp.order}`}
      draggable={editable}
      longitude={wp.longitude}
      latitude={wp.latitude}
      anchor="center"
      pitchAlignment="map"
      rotationAlignment="map"
      onClick={(e) => {
        if (e && e.originalEvent) {
          e.originalEvent.stopPropagation();
        }
        setSelectedWaypoint(wp);
      }}
      onDrag={handleDrag(wp)}
      onDragEnd={handleDragEnd}
    >
      {renderMarkerIcon(wp)}
    </Marker>
  );

  const renderConnections = () => {
    if (!waypoints?.length || waypoints.length < 2) return null;

    const missionWaypoints = filterMissionWaypoints(waypoints, isPolygonClosed);
    const features = [];
    const midpointMarkers = [];

    for (let i = 0; i < missionWaypoints.length - 1; i++) {
      const currentWp = missionWaypoints[i];
      const nextWp = missionWaypoints[i + 1];

      const distance = GeoHelper.calculateDistance(currentWp, nextWp);
      features.push({
        type: 'Feature',
        properties: {
          isInvalid: distance > 2000,
          distance,
          segmentId: `${i}-${i + 1}`
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            [currentWp.longitude, currentWp.latitude],
            [nextWp.longitude, nextWp.latitude]
          ]
        }
      });

      midpointMarkers.push(createMidpointMarker(currentWp, nextWp, i));
    }

    if (isPolygonClosed && missionWaypoints.length >= 2) {
      const lastWp = missionWaypoints[missionWaypoints.length - 1];
      const firstWp = missionWaypoints[0];

      const distance = GeoHelper.calculateDistance(lastWp, firstWp);
      features.push({
        type: 'Feature',
        properties: {
          isInvalid: distance > 2000,
          distance,
          segmentId: 'closing'
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            [lastWp.longitude, lastWp.latitude],
            [firstWp.longitude, firstWp.latitude]
          ]
        }
      });

      midpointMarkers.push(createMidpointMarker(lastWp, firstWp, missionWaypoints.length - 1));
    }

    return (
      <>
        <Source
          id="connections"
          type="geojson"
          data={{
            type: 'FeatureCollection',
            features
          }}
        >
          <Layer
            id="route"
            type="line"
            paint={{
              'line-color': ['case', ['get', 'isInvalid'], 'rgba(239, 68, 68, 0)', 'rgba(107, 114, 128, 0)'],
              'line-width': 2,
            }}
          />
        </Source>
        {midpointMarkers}
      </>
    );
  };

  const filterMissionWaypoints = (waypoints, isPolygonClosed) => {
    if (!waypoints?.length) {
      return [];
    }

    return waypoints.filter((wp) => wp.waypointType === 0 || wp.waypointType === 1);
  };

  const createMidpointMarker = (wp1, wp2, index) => {
    const midpoint = GeoHelper.calculateMidpoint(wp1, wp2);
    const distance = GeoHelper.calculateDistance(wp1, wp2);
    const isInvalid = distance > 2000;

    return (
      <Marker
        key={`midpoint-${wp1.order}-${wp2.order}`}
        longitude={midpoint.longitude}
        latitude={midpoint.latitude}
        anchor="center"
        pitchAlignment="map"
        rotationAlignment="map"
      >
        <div
          className="relative z-50 group"
          onClick={(e) => {
            e.stopPropagation();
            handleMidpointClick(wp1, wp2);
          }}
        >
          <div className="absolute -inset-4 cursor-pointer" />

          <div
            className={`absolute -top-8 left-1/2 transform -translate-x-1/2
            bg-white px-3 py-1.5 rounded-full shadow-md text-sm pointer-events-none
            transition-all duration-200 group-hover:-translate-y-1
            ${isInvalid ? 'text-red-500 border border-red-500' : 'text-gray-600'}`}
          >
            {Math.round(distance)}M
          </div>

          <motion.div
            className={`w-8 h-8 bg-white rounded-full flex items-center justify-center
            shadow-md cursor-pointer transition-all duration-200
            group-hover:shadow-lg group-hover:bg-blue-50
            ${isInvalid ? 'border-2 border-red-500' : 'border border-gray-200'}`}
            whileHover={{ scale: 1.15 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="absolute w-12 h-12 bg-blue-100 rounded-full opacity-0
            group-hover:opacity-20 transition-opacity duration-200"/>

            {/* Plus icon */}
            <Plus
              className={`${isInvalid ? 'text-red-500' : 'text-blue-500'}
              relative z-10 transition-transform duration-200 group-hover:scale-110`}
              size={20}
            />
          </motion.div>
        </div>
      </Marker>
    );
  };

  const renderMarkerIcon = (wp) => {
    const isInvalidDistance = invalidDistanceSegments.some(
      ([start, end]) => wp.order === start + 1 || wp.order === end + 1
    );

    const baseClasses = `w-10 h-10 bg-white rounded-full flex items-center justify-center shadow-lg ${isInvalidDistance ? 'border-2 border-red-500' : ''
      }`;

    const iconProps = {
      size: 20,
      strokeWidth: 2,
      className: "text-gray-700"
    };

    const hitboxContainer = (icon) => (
      <div className="relative" style={{ touchAction: 'none' }}>
        <div
          className="absolute"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '84px',
            height: '84px',
            pointerEvents: 'none',
            zIndex: 1000
          }}
        />

        <div className="relative flex items-center justify-center">
          <motion.div
            className={baseClasses}
            initial="initial"
            whileHover="hover"
            variants={markerVariants}
            animate={wp.waypointType === 1 ? pulseAnimation : "animate"}
          >
            {wp.waypointType === 0 ? (
              <Home {...iconProps} />
            ) : (
              <MapPin {...iconProps} />
            )}
          </motion.div>
        </div>

        <div
          className="absolute inset-0 cursor-crosshair"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '84px',
            height: '84px'
          }}
        />
      </div>
    );

    return hitboxContainer();
  };

  const photoArea = () => {
    if (waypoints.length > 3) {
      const polygonPoints = waypoints.filter((wp) => wp.waypointType === 1);
      const coordinates = polygonPoints.map((waypoint) => [waypoint.longitude, waypoint.latitude]);

      if (!isPolygonClosed || coordinates[0] !== coordinates[coordinates.length - 1]) {
        coordinates.push(coordinates[0]);
      }

      return (
        <>
          {!isIntersecting && (
            <Source
              id="buffer-area"
              type="geojson"
              data={{
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Polygon',
                  coordinates: [coordinates]
                }
              }}
            >
              <Layer
                id="buffer-fill"
                type="fill"
                paint={{
                  'fill-color': '#d4d7e4',
                  'fill-opacity': 0.4
                }}
              />
            </Source>
          )}
        </>
      );
    }
    return null;
  };

  // Filter the data based on mapStyle
  const filteredGeoData = filterFeatures(geoData, mapStyle === 'nofly-zones');
  const filteredGeoDataNoNatura2000 = filterFeatures(geoDataNoNatura2000, mapStyle === 'nofly-zones');

  return (<>
    <MapGL
      initialViewState={initialViewState}
      ref={mapRef}
      mapStyle={mapboxStyle}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      onClick={handleClick}
    >
      <GeocoderControl mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN} position="top-right" />
      <GeolocateControl position="bottom-right" />
      <FullscreenControl position="bottom-right" />
      <NavigationControl position="bottom-right" />
      <ScaleControl />

      <SnakePath isDrag={isDragging} />
      {renderConnections()}
      {markers()}
      {photoArea()}

      {selectedWaypoint && (
        <WaypointDetailsPopupCard
          waypoint={selectedWaypoint}
          draftMission={draftMission}
          setIsPopupModified={setIsPopupModified}
          updateDraftMission={updateDraftMission}
          onClose={() => setSelectedWaypoint(null)}
          isPolygonClosed={isPolygonClosed}
          setShouldGenerateSnakePath={setShouldGenerateSnakePath}
        />
      )}

      {waypoints && waypoints
        .filter((wp) => wp.order === 1 && wp.waypointType === 1)
        .map((wp) => (<Source
          key={`circle-source-${wp.order}`}
          id={`circle-source-${wp.order}`}
          type="geojson"
          data={{
            type: 'FeatureCollection', features: [{
              type: 'Feature', properties: {}, geometry: {
                type: 'Point', coordinates: [wp.longitude, wp.latitude],
              },
            },],
          }}
        >
          <Layer
            key={`circle-layer-${wp.order}`}
            id={`circle-layer-${wp.order}`}
            type="circle"
            paint={{
              'circle-radius': ['interpolate', ['exponential', 5], ['zoom'], 0, 0, 20, 300],
              'circle-color': 'lightblue',
              'circle-opacity': 0.6,
            }}
          />
        </Source>))}

      {lineStrings != null && (<Source id="my-data" type="geojson" data={lineStrings}>
        <Layer
          id="drone"
          type="line"
          paint={{
            'line-color': 'rgba(172, 172, 172, 0.5)', 'line-width': 3,
          }}
        />
      </Source>)}

      {drones != null && drones.map((drone) => (
        <DroneSource key={drone.droneId} drone={drone} selectedDrone={drone} onDroneFocusing={null} />))}

      {/* Render no-fly zone layers when data is available */}
      {(mapStyle === 'nofly-zones' || mapStyle === 'nofly-zones-all') && filteredGeoData && (
        <Source type="geojson" data={filteredGeoData}>
          <Layer
            id="drone-zones"
            type="fill"
            paint={{
              'fill-color': [
                'match',
                ['get', 'localtype'],
                'Verboden',
                [
                  'case',
                  ['>=', ['index-of', 'radio', ['get', 'sourceTxt']], 0],
                  '#FF0000', // Red if 'sourceTxt' contains 'radio'
                  '#FFA500', // Orange otherwise
                ],
                'Natura2000',
                '#3a7d15',
                'Beperkt toegestaan',
                '#FFA500',
                '#CCCCCC', // Default color
              ],
              'fill-opacity': 0.4,
            }}
          />
          <Layer
            id="zone-borders"
            type="line"
            paint={{
              'line-color': [
                'match',
                ['get', 'localtype'],
                'Verboden',
                [
                  'case',
                  ['>=', ['index-of', 'radio', ['get', 'sourceTxt']], 0],
                  '#FF0000', // Red if 'sourceTxt' contains 'radio'
                  '#FFA500', // Orange otherwise
                ],
                'Natura2000',
                '#3a7d15',
                'Beperkt toegestaan',
                '#FFA500',
                '#CCCCCC', // Default color
              ],
              'line-width': 2,
            }}
          />
        </Source>
      )}

      {(mapStyle === 'nofly-zones' || mapStyle === 'nofly-zones-all') && filteredGeoDataNoNatura2000 && (
        <Source type="geojson" data={filteredGeoDataNoNatura2000}>
          <Layer
            id="drone-zones-no-natura2000"
            type="fill"
            paint={{
              'fill-color': [
                'match',
                ['get', 'localtype'],
                'Verboden',
                [
                  'case',
                  ['>=', ['index-of', 'radio', ['get', 'sourceTxt']], 0],
                  '#FF0000', // Red if 'sourceTxt' contains 'radio'
                  '#FFA500', // Orange otherwise
                ],
                'Natura2000',
                '#3a7d15',
                'Beperkt toegestaan',
                '#FFA500',
                '#CCCCCC', // Default color
              ],
              'fill-opacity': 0.4,
            }}
          />
          <Layer
            id="zone-borders-no-natura2000"
            type="line"
            paint={{
              'line-color': [
                'match',
                ['get', 'localtype'],
                'Verboden',
                [
                  'case',
                  ['>=', ['index-of', 'radio', ['get', 'sourceTxt']], 0],
                  '#FF0000', // Red if 'sourceTxt' contains 'radio'
                  '#FFA500', // Orange otherwise
                ],
                'Natura2000',
                '#3a7d15',
                'Beperkt toegestaan',
                '#FFA500',
                '#CCCCCC', // Default color
              ],
              'line-width': 2,
            }}
          />
        </Source>
      )}
    </MapGL>
    <div className="overlay" />
  </>);
}
