import React, { useContext } from 'react';
import { SideNavContext } from '../SideNav';

// SVG Icons
const SvgIcons = {
  Sun: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`weather-icon ${props.className || ''}`}>
      <circle cx="12" cy="12" r="4"></circle>
      <path d="M12 2v2"></path>
      <path d="M12 20v2"></path>
      <path d="m4.93 4.93 1.41 1.41"></path>
      <path d="m17.66 17.66 1.41 1.41"></path>
      <path d="M2 12h2"></path>
      <path d="M20 12h2"></path>
      <path d="m6.34 17.66-1.41 1.41"></path>
      <path d="m19.07 4.93-1.41 1.41"></path>
    </svg>
  ),
  Moon: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`weather-icon ${props.className || ''}`}>
      <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path>
    </svg>
  ),
  Cloud: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`weather-icon ${props.className || ''}`}>
      <path d="M17.5 19H9a7 7 0 1 1 6.71-9h1.79a4.5 4.5 0 1 1 0 9Z"></path>
    </svg>
  ),
  CloudDrizzle: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`weather-icon ${props.className || ''}`}>
      <path d="M7 19v2"></path>
      <path d="M13 19v2"></path>
      <path d="M19 19v2"></path>
      <path d="M7 13v2"></path>
      <path d="M13 13v2"></path>
      <path d="M19 13v2"></path>
      <path d="M16 8a5 5 0 0 0-9.85-1.34A4 4 0 0 0 4 15h13a3 3 0 0 0 0-6h-1Z"></path>
    </svg>
  ),
  CloudRain: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`weather-icon ${props.className || ''}`}>
      <path d="M16 13v8"></path>
      <path d="M8 13v8"></path>
      <path d="M12 15v8"></path>
      <path d="M16 8a5 5 0 0 0-9.85-1.34A4 4 0 0 0 4 15h13a3 3 0 0 0 0-6h-1Z"></path>
    </svg>
  ),
  CloudLightning: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`weather-icon ${props.className || ''}`}>
      <path d="M6 16.33V13a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v3.33"></path>
      <path d="M13 16.33V13a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v3.33"></path>
      <path d="M11 10V3l5 7H4"></path>
      <path d="M17 20H7a4 4 0 0 1-4-4 4 4 0 0 1 4-4h10a4 4 0 0 1 4 4 4 4 0 0 1-4 4Z"></path>
    </svg>
  ),
  CloudSnow: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`weather-icon ${props.className || ''}`}>
      <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242"></path>
      <path d="M8 15h.01"></path>
      <path d="M8 19h.01"></path>
      <path d="M12 17h.01"></path>
      <path d="M12 21h.01"></path>
      <path d="M16 15h.01"></path>
      <path d="M16 19h.01"></path>
    </svg>
  ),
  Droplets: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`weather-icon ${props.className || ''}`}>
      <path d="M7 16.3c2.2 0 4-1.83 4-4.05 0-1.16-.57-2.26-1.71-3.19S7.29 6.75 7 5.3c-.29 1.45-1.14 2.84-2.29 3.76S3 11.1 3 12.25c0 2.22 1.8 4.05 4 4.05z"></path>
      <path d="M12.56 6.6A10.97 10.97 0 0 0 14 3.02c.5 2.5 2 4.9 4 6.5s3 3.5 3 5.5a6.98 6.98 0 0 1-11.91 4.97"></path>
    </svg>
  ),
  Wind: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`weather-icon ${props.className || ''}`}>
      <path d="M17.7 7.7a2.5 2.5 0 1 1 1.8 4.3H2"></path>
      <path d="M9.6 4.6A2 2 0 1 1 11 8H2"></path>
      <path d="M12.6 19.4A2 2 0 1 0 14 16H2"></path>
    </svg>
  )
};

export default function WeatherIcon({ icon, clickEnabled }) {
  const { weatherTooltipOpen, setWeatherTooltipOpen, setNotificationsTooltipOpen } = useContext(SideNavContext);

  const handleClick = () => {
    if (clickEnabled) {
      setWeatherTooltipOpen(!weatherTooltipOpen);

      if (!weatherTooltipOpen) {
        setNotificationsTooltipOpen(false);
      }
    }
  };

  // Render the appropriate weather icon based on the OpenWeather code
  const renderWeatherIcon = () => {
    if (!icon) {
      return <SvgIcons.Cloud size={20} />;
    }

    // Map OpenWeather icon codes to SVG icons
    switch (icon) {
      case '01d': // clear sky day
        return <SvgIcons.Sun className="sun" size={20} />;
      case '01n': // clear sky night
        return <SvgIcons.Moon className="moon" size={20} />;
      case '02d': // few clouds day
      case '02n': // few clouds night
      case '03d': // scattered clouds day
      case '03n': // scattered clouds night
      case '04d': // broken clouds day
      case '04n': // broken clouds night
        return <SvgIcons.Cloud className="cloud" size={20} />;
      case '09d': // shower rain day
      case '09n': // shower rain night
        return <SvgIcons.CloudDrizzle className="cloud-drizzle" size={20} />;
      case '10d': // rain day
      case '10n': // rain night
        return <SvgIcons.CloudRain className="cloud-rain" size={20} />;
      case '11d': // thunderstorm day
      case '11n': // thunderstorm night
        return <SvgIcons.CloudLightning className="cloud-lightning" size={20} />;
      case '13d': // snow day
      case '13n': // snow night
        return <SvgIcons.CloudSnow className="snowflake" size={20} />;
      case '50d': // mist day
      case '50n': // mist night
        return <SvgIcons.Droplets className="fog" size={20} />;
      default:
        return <SvgIcons.Cloud size={20} />;
    }
  };

  return (
    <div 
      className="weather-icon-container" 
      onClick={handleClick}
      style={{ cursor: clickEnabled ? 'pointer' : 'default' }}
    >
      {renderWeatherIcon()}
    </div>
  );
}
