// eslint-disable-next-line import/prefer-default-export
export class Waypoint {
  constructor(json) {
    this.waypointId = json.waypointId;
    this.missionId = json.missionId;
    this.waypointType = json.waypointType;
    this.name = json.name;
    this.longitude = json.longitude;
    this.latitude = json.latitude;
    this.altitude = json.altitude;
    this.heading = json.heading;
    this.order = json.order;
  }

  sameLocationAs(waypoint) {
    if (waypoint === undefined) return false;

    if (this.waypointType !== waypoint.waypointType) return false;

    return (
      this.latitude === waypoint.latitude && this.longitude === waypoint.longitude && this.order !== waypoint.order
    );
  }
}
