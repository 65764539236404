import { useState, useEffect } from 'react';
import * as turf from '@turf/turf';
import proj4 from 'proj4';
import DroneCheckService from '../services/AutomaticChecks/droneChecks.service';
import MissionService from '../services/Missions/mission.service';

export default function useDroneCheckService(telemetryData, missionId) {
  const [isDroneConnected, setIsDroneConnected] = useState(null);
  const [isBatteryLevelEnough, setIsBatteryLevelEnough] = useState(null);
  const [isInternetConnectionLive, setIsInternetConnectionLive] = useState(null);
  const [areChecksPending, setAreChecksPending] = useState(null);
  const [areHealthChecksFailing, setAreHealthChecksFailing] = useState(null); // TODO: Test this with drone low RC and no sattelites

  const [geoData, setGeoData] = useState(null);
  const [geoDataNoNatura2000, setGeoDataNoNatura2000] = useState(null);
  const [isWithinNoFlyZoneState, setIsWithinNoFlyZone] = useState(false);

  const [softwareErrors, setSoftwareErrors] = useState([]);
  const [missionErrors, setMissionErrors] = useState([]);

  // Define the EPSG:28992 projection
  const RDNew = 'EPSG:28992';
  const WGS84 = 'EPSG:4326';

  // Fetch and reproject GeoJSON data on component mount
  useEffect(() => {
    fetch(
      'https://service.pdok.nl/lvnl/drone-no-flyzones/wfs/v1_0?request=GetFeature&service=WFS&version=2.0.0&typeName=drone-no-flyzones:luchtvaartgebieden&outputFormat=application/json'
    )
      .then((response) => response.json())
      .then((data) => {
        const reprojectedData = {
          ...data,
          features: data.features.map((feature) => {
            const { geometry } = feature;
            if (geometry.type === 'Polygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: [geometry.coordinates[0].map((point) => proj4(RDNew, WGS84, point))],
                },
              };
            }
            if (geometry.type === 'MultiPolygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: geometry.coordinates.map((polygon) =>
                    polygon.map((point) => proj4(RDNew, WGS84, point))
                  ),
                },
              };
            }
            return feature;
          }),
        };
        setGeoData(reprojectedData);
      })
      .catch((err) => console.error('Error loading geo data: ', err));

    fetch(
      'https://service.pdok.nl/lvnl/drone-no-flyzones/wfs/v1_0?request=GetFeature&service=WFS&version=2.0.0&typeName=drone-no-flyzones:luchtvaartgebieden_zonder_natura2000&outputFormat=application/json'
    )
      .then((response) => response.json())
      .then((data) => {
        const reprojectedData = {
          ...data,
          features: data.features.map((feature) => {
            const { geometry } = feature;
            if (geometry.type === 'Polygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: [geometry.coordinates[0].map((point) => proj4(RDNew, WGS84, point))],
                },
              };
            }
            if (geometry.type === 'MultiPolygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: geometry.coordinates.map((polygon) =>
                    polygon.map((point) => proj4(RDNew, WGS84, point))
                  ),
                },
              };
            }
            return feature;
          }),
        };
        setGeoDataNoNatura2000(reprojectedData);
      })
      .catch((err) => console.error('Error loading geo data (zonder Natura 2000): ', err));
  }, []);

  useEffect(() => {
    async function fetchSoftwareChecks() {
      try {
        const droneCheckService = new DroneCheckService();
        const data = await droneCheckService.getSoftwareChecks(telemetryData);
        if (data) {
          setIsDroneConnected(data.isDroneConnected);
          setIsBatteryLevelEnough(data.isBatteryLevelEnough);
          setIsInternetConnectionLive(data.isInternetConnectionLive);
          setAreChecksPending(data.areChecksPending);
          setAreHealthChecksFailing(data.areHealthChecksFailing);
          setSoftwareErrors(data.droneErrors);
        }
      } catch (err) {
        console.error(err);
      }
    }

    fetchSoftwareChecks();
  }, [telemetryData]);

  // TODO: This should probably be moved to dronecheckservice or some mission check service
  useEffect(() => {
    async function getMissionWaypoints() {
      const response = await MissionService.getMissionWaypoints(missionId);
      let isWithinNoFlyZone = false;

      const checkWaypointInNoFlyZone = (waypoints, zoneData) => {
        waypoints.forEach((waypoint, index) => {
          const point = turf.point([waypoint.longitude, waypoint.latitude]);
          const isInNoFlyZone = zoneData.features.some((feature) => {
            const polygon = turf.polygon(feature.geometry.coordinates);
            return turf.booleanWithin(point, polygon);
          });

          if (isInNoFlyZone) {
            isWithinNoFlyZone = true;
            if (missionErrors.length === 0) {
              setMissionErrors([
                {
                  title: 'No-fly zone',
                  description: `Waypoint ${index + 1} is in a no-fly zone`,
                },
              ]);
            }
          }
        });
      };

      if (geoData && geoData.features && !isWithinNoFlyZone) {
        checkWaypointInNoFlyZone(response.data, geoData);
      }

      if (geoDataNoNatura2000 && geoDataNoNatura2000.features && !isWithinNoFlyZone) {
        checkWaypointInNoFlyZone(response.data, geoDataNoNatura2000);
      }

      setIsWithinNoFlyZone(isWithinNoFlyZone);
    }

    getMissionWaypoints();
  }, [geoDataNoNatura2000]);

  return {
    isWithinNoFlyZoneState,
    errors: [...softwareErrors, ...missionErrors],
    areChecksPending,
    areHealthChecksFailing,
  };
}
