import React from 'react';
import { motion } from 'framer-motion';

const PlanningSelector = ({ selected, onSelect }) => {
  const getSliderPosition = () => {
    const buttonWidth = 100;
    switch (selected) {
      case 'low':
        return 0;
      case 'medium':
        return buttonWidth * 1.03;
      case 'high':
        return buttonWidth * 2.08;
      default:
        return 0;
    }
  };

  return (
    <div className="w-full font-sans">
      {/* <div className="text-left mb-2">
        <h2 className="mt-4 text-sm text-missionsText">Quality</h2>
      </div> */}

      <div className="relative w-full bg-white rounded-xl p-[2px] border border-[#e6e6e6]">
        <motion.div
          className="absolute inset-0.5 rounded-xl bg-[#4359e8]"
          initial={false}
          animate={{
            x: `${getSliderPosition()}%`,
            width: 'calc(33.333% - 4px)'
          }}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 30
          }}
        />
        <div className="relative flex flex-col items-center pt-0 pb-3">
          <div className="flex w-full">
            <div className="flex-1 text-center cursor-pointer" onClick={() => onSelect('low')}>
              <button
                className={`py-2.5 px-6 rounded-full z-10 font-medium transition-colors ${selected === 'low' ? 'text-white' : 'text-gray-700'
                  }`}
              >
                Fast
              </button>
              <p className={`text-xs mt-[-12px] ${selected === 'low' ? 'text-white' : 'text-gray-500'}`}>1.5 cm</p>
            </div>
            <div className="flex-1 text-center cursor-pointer" onClick={() => onSelect('medium')}>
              <button
                className={`py-2.5 px-6 rounded-full z-10 font-medium transition-colors ${selected === 'medium' ? 'text-white' : 'text-gray-700'
                  }`}
              >
                Medium
              </button>
              <p className={`text-xs mt-[-12px] ${selected === 'medium' ? 'text-white' : 'text-gray-500'}`}>1 cm</p>
            </div>
            <div className="flex-1 text-center cursor-pointer" onClick={() => onSelect('high')}>
              <button
                className={`py-2.5 px-6 rounded-full z-10 font-medium transition-colors ${selected === 'high' ? 'text-white' : 'text-gray-700'
                  }`}
              >
                Slow
              </button>
              <p className={`text-xs mt-[-12px] ${selected === 'high' ? 'text-white' : 'text-gray-500'}`}>0.5 cm</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanningSelector;
