import React, { useContext } from 'react';
import { MissionsContext } from "../../pages/Missions/Missions";

const MissionInfoPanel = () => {
  const { missionTimeInMinutes, isMissionPossible, snakePathWaypoints } = useContext(MissionsContext);

  // Format time with fallback for invalid values
  const getFormattedTime = (time) => {
    if (typeof time !== 'number' || isNaN(time) || time <= 0) {
      return '--';
    }
    return Math.ceil(time);
  };

  // Only show the panel if we have snake path waypoints
  if (!snakePathWaypoints || !Array.isArray(snakePathWaypoints) || snakePathWaypoints.length === 0) {
    return null;
  }

  const formattedTime = getFormattedTime(missionTimeInMinutes);
  
  // Don't display if time is invalid and mission possibility is undefined
  if (formattedTime === '--' && isMissionPossible === undefined) {
    return null;
  }

  // Determine background color based on mission possibility (inverted logic)
  const getBgColor = () => {
    if (isMissionPossible === false) {
      return '#fef2f2'; // Red background for impossible missions
    } else if (isMissionPossible === true) {
      return '#ecfdf5'; // Green background for possible missions
    } else {
      return '#f9fafb'; // Light gray background for undefined
    }
  };

  // Determine text color based on mission possibility
  const getTextColor = () => {
    if (isMissionPossible === false) {
      return '#b91c1c'; // Red text for impossible
    } else if (isMissionPossible === true) {
      return '#047857'; // Green text for possible
    } else {
      return '#4b5563'; // Gray text for undefined
    }
  };

  return (
    <div className="fixed top-16 right-4 z-50" data-testid="mission-info-panel">
      <div style={{ 
        backgroundColor: getBgColor(),
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
        border: `1px solid ${isMissionPossible === false ? '#fee2e2' : (isMissionPossible === true ? '#d1fae5' : '#e5e7eb')}`,
        borderRadius: '0.5rem',
        padding: '0.5rem 0.75rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem'
      }}>
        {/* Flight time */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
          <svg 
            className="w-3.5 h-3.5 text-gray-600" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2"
            style={{ width: '14px', height: '14px', color: '#4b5563' }}
          >
            <circle cx="12" cy="12" r="10" />
            <polyline points="12 6 12 12 16 14" />
          </svg>
          <span style={{ 
            fontSize: '12px', 
            fontWeight: '500',
            color: '#4b5563'
          }}>
            {formattedTime} min
          </span>
        </div>
        
        {/* Divider */}
        <div style={{ height: '14px', width: '1px', backgroundColor: '#d1d5db' }}></div>
        
        {/* Mission status */}
        {isMissionPossible !== undefined && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
            {isMissionPossible ? (
              <svg 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
                style={{ width: '14px', height: '14px', color: '#047857' }}
              >
                <path d="M20 6L9 17l-5-5" />
              </svg>
            ) : (
              <svg 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
                style={{ width: '14px', height: '14px', color: '#b91c1c' }}
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="12" y1="8" x2="12" y2="12" />
                <line x1="12" y1="16" x2="12.01" y2="16" />
              </svg>
            )}
            <span style={{ 
              fontSize: '12px', 
              fontWeight: '500', 
              color: getTextColor()
            }}>
              {isMissionPossible ? "Possible" : "Mission too long"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MissionInfoPanel; 