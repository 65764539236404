import React, { useContext, useEffect, useState } from 'react';
import { MissionsContext } from "../../pages/Missions/Missions";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { useGlobalModal } from "../Modal/GlobalModalContext";
import { Home, Check, MapPin, Trash, AlertCircle, ChevronDown } from "lucide-react";
import GeoHelper from "../../services/geoservice";
import ImagePlanningSelector from "./ImagePlanningSelector";
import GenericService from "../../services/generic.service";
import AdvancedSettings from './Settings/AdvancedSettings';

const PLANNING_STATES = {
  IDLE: 'IDLE',
  ADDING_HOME: 'ADDING_HOME',
  DRAWING_POLYGON: 'DRAWING_POLYGON',
  DRAWN_POLYGON: 'DRAWN_POLYGON',
  UPDATE_MISSION: 'UPDATE_MISSION'
};


export function Planning() {
  const {
    updateDraftMission,
    draftMission,
    setIsPolygonClosed,
    setShouldGenerateSnakePath,
    isPolygonClosed,
    isIntersecting,
    setIsIntersecting,
    invalidDistanceSegments,
    shouldCreateSnakePath,
    setSnakePathWaypoints,
    allMissions,
    setShouldCreateSnakePath,
    isDragging,
    complexityLevel,
    setComplexityLevel,
    missionTimeInMinutes,
    isMissionPossible,
    snakePathWaypoints
  } = useContext(MissionsContext);

  const { showGlobalModal, hideGlobalModal } = useGlobalModal();
  const [planningState, setPlanningState] = useState(PLANNING_STATES.ADDING_HOME);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (draftMission.waypoints.length === 4) {
      setIsPolygonClosed(true);
      setIsIntersecting(false);
      setShouldGenerateSnakePath(true);
      setPlanningState(PLANNING_STATES.DRAWN_POLYGON);
      hideGlobalModal();
    }
  }, [draftMission, isPolygonClosed, planningState]);

  useEffect(() => {
    updateDraftMission({ waypoints: draftMission.waypoints, clickHandler: handleMapClick });
  }, [planningState, draftMission.waypoints]);

  useEffect(() => {
    hideGlobalModal();

    if (draftMission.waypoints.length !== 0) {
      setPlanningState(PLANNING_STATES.UPDATE_MISSION);
      setShouldGenerateSnakePath(true);
      setIsPolygonClosed(true);
    }
  }, []);

  const handleIntegerInput = (value, setter) => {
    const cleanValue = value.replace(/[^0-9]/g, '');
    setter(cleanValue);
    return cleanValue;
  };

  const handleObjectOfInterestHeight = (e) => {
    let objectOfInterestHeight = e.target.value.replace(/[^0-9]/g, '');

    if(objectOfInterestHeight === "") objectOfInterestHeight = 0;

    updateDraftMission({ missionSettings: { objectOfInterestHeight } })
  }
  const handleMapClick = (e) => {
    // Start of planning, after clicking once you can add homepoint
    if (planningState === PLANNING_STATES.IDLE) {
      handlePrepareAddHomeWaypoint();
    }

    if (planningState === PLANNING_STATES.ADDING_HOME) {
      const homePoint = {
        latitude: e.lngLat.lat,
        longitude: e.lngLat.lng,
        altitude: 20,
        heading: 0,
        waypointType: 0,
        order: 0
      };

      updateDraftMission({
        homeWaypoint: homePoint
      });

      setPlanningState(PLANNING_STATES.DRAWING_POLYGON);
      hideGlobalModal();
      showGlobalModal({
        icon: MapPin,
        title: "Draw the polygon",
        description: "Add at least 4 waypoints to draw your polygon",
        position: 'top-right',
        type: 'info'
      });

    } else if (planningState === PLANNING_STATES.DRAWING_POLYGON) {
      const newWaypoint = {
        latitude: e.lngLat.lat,
        longitude: e.lngLat.lng,
        altitude: 20,
        heading: 0,
        waypointType: 1,
        order: draftMission.waypoints.length + 1
      };

      updateDraftMission({
        waypoints: [...draftMission.waypoints, newWaypoint]
      });

      const hasIntersection = GeoHelper.checkForIntersections(draftMission.waypoints, null, newWaypoint);
      if (hasIntersection) {
        setIsIntersecting(true);
      } else {
        setIsIntersecting(false);
        hideGlobalModal();
      }
    } else if (planningState === PLANNING_STATES.DRAWN_POLYGON || planningState === PLANNING_STATES.UPDATE_MISSION) {
      const currentWaypoints = [...draftMission.waypoints];

      const newWaypoint = {
        latitude: e.lngLat.lat,
        longitude: e.lngLat.lng,
        altitude: 20,
        heading: 0,
        waypointType: 1,
        order: currentWaypoints.length + 1
      };

      const updatedWaypoints = [...currentWaypoints, newWaypoint];

      updateDraftMission({
        waypoints: updatedWaypoints
      });
      setShouldGenerateSnakePath(true);
      setIsPolygonClosed(true);
    }
  }

  const handlePrepareAddHomeWaypoint = () => {
    setIsLoading(true);
    setPlanningState(PLANNING_STATES.ADDING_HOME);
    showGlobalModal({
      icon: Home,
      title: "Set Home Waypoint",
      description: "Set the starting position of your drone. After this step, you will be able to draw your whole polygon.",
      position: 'top-right',
      type: 'info'
    });
  };

  const handleComplexityChange = (level) => {
    const qualityLevels = { "low": 2, "medium": 1, "high": 0 };

    setComplexityLevel(level);

    updateDraftMission({
      qualitySettings: {
        level: qualityLevels[level],
      },
    });
  };

  return (
    <div className="relative">
      <motion.div
        className="mb-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="mb-6"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <h2 className="mb-2 text-sm text-missionsText">Quality</h2>
          <ImagePlanningSelector
            selected={complexityLevel}
            onSelect={handleComplexityChange}
          />
        </motion.div>
      </motion.div>

      {/* Mission and Advanced Settings */}
      <motion.div
        className="mt-10"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
      >
        <div className="space-y-4">
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4 }}
          >
            <div className="bg-white rounded-lg border border-gray-200 hover:border-gray-300 transition-colors duration-200 p-4">
              <div className="flex items-start justify-between mb-6">
                <div className="flex items-center gap-3">
                  <div className="bg-gray-50 p-2 rounded-lg">
                    <svg 
                      className="w-5 h-5 text-[rgb(123,139,157)]" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2"
                    >
                      <path d="M20 20H4V4" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M4 16.5L12 9L16 12.5L20 8.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                  <div>
                    <h2 className="text-sm font-medium text-[rgb(123,139,157)]">Object Height</h2>
                    <p className="text-xs text-[rgb(123,139,157)]">Highest point of target object</p>
                  </div>
                </div>
                <img src={require('../../assets/images/height-illustration.png')} alt="Height" className="w-16 h-16 object-contain" />
              </div>
              <div className="relative">
                <input
                  type="text"
                  value={draftMission.missionSettings.objectOfInterestHeight}
                  onChange={(e) => handleObjectOfInterestHeight(e)}
                  placeholder="Enter height in meters..."
                  className="w-full p-3 pl-4 pr-16 rounded-lg bg-gray-50 text-[rgb(123,139,157)] placeholder:text-gray-400 border border-gray-200 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-200 transition-colors duration-200"
                />
                <div className="absolute right-3 top-1/2 -translate-y-1/2 px-2 py-1 bg-gray-100 rounded text-[rgb(123,139,157)] text-sm">
                  meters
                </div>
              </div>
            </div>
          </motion.div>

          {/* Remove the entire Mission Info Panel section */}

        </div>

        <AdvancedSettings
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          draftMission={draftMission}
          updateDraftMission={updateDraftMission}
        />
      </motion.div>
    </div>
  );
}
