import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import PropTypes from 'prop-types';

/**
 * This component renders the Loader element
 * which displays a loader animation (can be used for async functions)
 */


const Loader = ({ size = 80 }) => (
  <TailSpin
    height={size}
    width={size}
    color="#1560BD"
    ariaLabel="tail-spin-loading"
    radius="1"
    wrapperStyle={{}}
    wrapperClass=""
  />
);

Loader.propTypes = {
  size: PropTypes.number,
};

export default Loader;