import React from "react";
import { Search } from 'lucide-react';

const SearchBar = ({ value, onChange }) => (
  <div className="relative w-full group hover:opacity-40">
    <input
      type="text"
      value={value}
      onChange={onChange}
      placeholder="Search missions..."
      className="w-full p-3 pl-10 rounded-lg bg-white font-inter text-[#070e17] outline-none"
    />
    <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-[#7B8B9D] w-5 h-5 group-focus-within:text-[#3a33f5] outline-none" />
  </div>
);
export default SearchBar;
