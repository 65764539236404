// AddApps.jsx
import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import MissionScrollView from './MissionScrollView';
import AppCard from './AppCard';
import GenericService from "../../services/generic.service";
import MissionLoader from "./MissionLoader";
import { MissionsContext } from "../../pages/Missions/Missions";

const MOCK_APPS = [
  { id: 1, name: "Camera App", description: "High-resolution image capture and processing" },
  { id: 2, name: "Weather Station", description: "Real-time atmospheric monitoring system" },
];

const containerVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { staggerChildren: 0.1 }
  }
};

const itemVariants = {
  initial: { opacity: 0, x: -20 },
  animate: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3 }
  }
};

export function AddApps() {
  const {
    draftMission,
    updateDraftMission,
  } = useContext(MissionsContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredApps, setFilteredApps] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const [apps, setApps] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedApps, setSelectedApps] = useState();

  useEffect(() => {
    fetchApps();

    //TODO: This should be done differently. Backend returns missionsApps with "appId". Get apps returns only "id". Inconsistency
    const transformedApps = draftMission.missionApps.map(app => ({
      ...app,
      id: app.appId,
      app: {
        ...app.app,
        id: app.appId
      }
    }));
  
    setSelectedApps(transformedApps);
  }, []);

  useEffect(() => {
    const filtered = apps?.filter(app =>
      app.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      app.description.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];
    setFilteredApps(filtered);
  }, [searchTerm, apps]);

  const fetchApps = () => {
    try {
      GenericService.AppStoreService.GetAllApps().then((response) => {
        setApps(response);
        setFilteredApps(response);
        setIsLoading(false)
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  }

  const handleToggleAppSelection = (app) => {
    setSelectedApps(prev => {
      const exists = prev.find(a => a.id === app.id);

      const newSelection = exists
        ? prev.filter(a => a.id !== app.id)
        : [...prev, app];

      return newSelection;
    });
  }

  useEffect(() => {
    updateDraftMission({
      missionApps: selectedApps
    })
  }, [selectedApps])

  if (isLoading) {
    return <MissionLoader isLoading={isLoading} />;
  }

  return (
    <motion.div
      className="flex flex-col font-inter"
      variants={containerVariants}
      initial="initial"
      animate="animate"
    >
      <motion.div
        className="mb-2 px-1"
        variants={itemVariants}
      >
        <h2 className="mt-4 text-sm text-[rgb(123,139,157)]">Apps</h2>
      </motion.div>

      <motion.div
        className="relative mb-4"
        variants={itemVariants}
      >
        <input
          type="text"
          placeholder="Search apps..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full h-14 px-4 py-2 border border-gray-300 font-inter rounded-lg bg-[#fbfbfb] focus:outline-none"
        />
      </motion.div>

      <div className="flex-1">
        <MissionScrollView>
          {filteredApps.map((app) => (
            <AppCard
              key={app.id}
              app={app}
              isHovered={hoveredId === app.id}
              isSelected={selectedApps.some(a => a.id === app.id)}
              onMouseEnter={() => setHoveredId(app.id)}
              onMouseLeave={() => setHoveredId(null)}
              onClick={() => handleToggleAppSelection(app)}
            />
          ))}
        </MissionScrollView>
      </div>
    </motion.div>
  );
}
