class GeoHelper {
  // Earth's radius in meters
  static EARTH_RADIUS = 6371e3;

  /**
   * Convert degrees to radians
   * @param {number} degrees Angle in degrees
   * @returns {number} Angle in radians
   */
  static toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  /**
   * Calculate the great-circle distance between two geographic points using the Haversine formula.
   * This method accounts for Earth's spherical shape and calculates the shortest distance
   * between two points along the surface of the sphere.
   *
   * Logic:
   * 1. Convert lat/long to radians
   * 2. Calculate differences in coordinates
   * 3. Apply Haversine formula: a = sin²(Δlat/2) + cos(lat1)·cos(lat2)·sin²(Δlong/2)
   * 4. Calculate final distance: d = R·2·atan2(√a, √(1−a))
   *
   * @param {Object} point1 - {latitude, longitude}
   * @param {Object} point2 - {latitude, longitude}
   * @returns {number} Distance in meters, rounded to nearest meter
   */
  static calculateDistance(point1, point2) {
    const lat1 = this.toRadians(point1.latitude);
    const lat2 = this.toRadians(point2.latitude);
    const deltaLat = this.toRadians(point2.latitude - point1.latitude);
    const deltaLong = this.toRadians(point2.longitude - point1.longitude);

    const a = Math.sin(deltaLat/2) * Math.sin(deltaLat/2) +
      Math.cos(lat1) * Math.cos(lat2) *
      Math.sin(deltaLong/2) * Math.sin(deltaLong/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    return Math.round(this.EARTH_RADIUS * c);
  }

  /**
   * Calculate the geographic midpoint between two points using the spherical law of cosines.
   * This method finds the intermediate point on the great circle path between two points.
   *
   * Logic:
   * 1. Convert coordinates to radians
   * 2. Calculate cartesian coordinates for the midpoint
   * 3. Convert back to latitude/longitude
   *
   * Note: This method gives the true geometric midpoint on the sphere, which may not be
   * the same as the visual midpoint on some map projections.
   *
   * @param {Object} point1 - {latitude, longitude}
   * @param {Object} point2 - {latitude, longitude}
   * @returns {Object} Midpoint coordinates {latitude, longitude}
   */
  static calculateMidpoint(point1, point2) {
    const long1 = this.toRadians(point1.longitude);
    const long2 = this.toRadians(point2.longitude);
    const lat1 = this.toRadians(point1.latitude);
    const lat2 = this.toRadians(point2.latitude);

    const cartesianX = Math.cos(lat2) * Math.cos(long2 - long1);
    const cartesianY = Math.cos(lat2) * Math.sin(long2 - long1);

    const resultLat = Math.atan2(
      Math.sin(lat1) + Math.sin(lat2),
      Math.sqrt((Math.cos(lat1) + cartesianX) * (Math.cos(lat1) + cartesianX) + cartesianY * cartesianY)
    );
    const resultLong = long1 + Math.atan2(cartesianY, Math.cos(lat1) + cartesianX);

    return {
      latitude: (resultLat * 180) / Math.PI,
      longitude: (resultLong * 180) / Math.PI
    };
  }

  /**
   * Determines if two line segments intersect using linear algebra.
   *
   * Logic:
   * 1. Uses parametric form of line equations
   * 2. Solves system of equations to find intersection point
   * 3. Checks if intersection point lies within both line segments
   *
   * @param {Array} line1Start - [longitude, latitude]
   * @param {Array} line1End - [longitude, latitude]
   * @param {Array} line2Start - [longitude, latitude]
   * @param {Array} line2End - [longitude, latitude]
   * @returns {boolean} True if lines intersect, false otherwise
   */
  static checkLineIntersection(line1Start, line1End, line2Start, line2End) {
    const x1 = line1Start[0], y1 = line1Start[1];
    const x2 = line1End[0], y2 = line1End[1];
    const x3 = line2Start[0], y3 = line2Start[1];
    const x4 = line2End[0], y4 = line2End[1];

    // Calculate denominator for intersection check
    const denominator = ((y4 - y3) * (x2 - x1)) - ((x4 - x3) * (y2 - y1));
    if (denominator === 0) return false; // Lines are parallel

    // Calculate intersection parameters
    const ua = (((x4 - x3) * (y1 - y3)) - ((y4 - y3) * (x1 - x3))) / denominator;
    const ub = (((x2 - x1) * (y1 - y3)) - ((y2 - y1) * (x1 - x3))) / denominator;

    // Check if intersection occurs within both line segments
    return (ua > 0 && ua < 1) && (ub > 0 && ub < 1);
  }

  /**
   * Checks if a polygon would self-intersect after moving a waypoint to a new position.
   *
   * Logic:
   * 1. Filters waypoints to include only main waypoints (type 0)
   * 2. Creates array of points with the dragged waypoint at its new position
   * 3. Checks each line segment against all non-adjacent segments for intersections
   * 4. Returns true if any intersections are found
   *
   * @param {Array} waypoints - Array of waypoint objects
   * @param {Object} draggedWaypoint - The waypoint being moved
   * @param {Object} newPosition - {latitude, longitude} of proposed new position
   * @returns {boolean} True if polygon would self-intersect, false otherwise
   */
  static checkForIntersections(waypoints, draggedWaypoint, newPosition) {
    if (waypoints.length < 4) return false;

    const points = waypoints
      .filter(waypoint => waypoint.waypointType === 1)
      .map(waypoint => waypoint === draggedWaypoint ?
        [newPosition.longitude, newPosition.latitude] :
        [waypoint.longitude, waypoint.latitude]
      );

    // Check each line segment against others
    for (let i = 0; i < points.length - 1; i++) {
      for (let j = i + 2; j < points.length; j++) {
        if (j === i + 1) continue; // Skip adjacent lines

        const line1Start = points[i];
        const line1End = points[i + 1];
        const line2Start = points[j];
        const line2End = j === points.length - 1 ? points[0] : points[j + 1];

        if (this.checkLineIntersection(line1Start, line1End, line2Start, line2End)) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Calculate the area of a polygon on the Earth's surface using the geodesic area formula.
   *
   * Logic:
   * 1. Uses a modified version of the planar shoelace formula adapted for spherical geometry
   * 2. Accounts for the Earth's curvature when calculating area
   * 3. Formula: A = R²|Σ(λᵢ₊₁ - λᵢ)(2 + sinφᵢ + sinφᵢ₊₁)|/4
   * where R is Earth's radius, λ is longitude, and φ is latitude
   *
   * @param {Array} points - Array of {latitude, longitude} objects
   * @returns {number} Area in square meters, rounded to nearest meter
   */
  static calculatePolygonArea(points) {
    if (points.length < 3) return 0;

    let area = 0;
    for (let i = 0; i < points.length; i++) {
      const j = (i + 1) % points.length;
      const long1 = this.toRadians(points[i].longitude);
      const long2 = this.toRadians(points[j].longitude);
      const lat1 = this.toRadians(points[i].latitude);
      const lat2 = this.toRadians(points[j].latitude);

      area += (long2 - long1) * (2 + Math.sin(lat1) + Math.sin(lat2));
    }

    area = Math.abs(area * this.EARTH_RADIUS * this.EARTH_RADIUS / 4);
    return Math.round(area);
  }

  /**
   * Calculate the initial bearing (forward azimuth) between two points.
   * This is the initial heading you would follow to get from point1 to point2.
   *
   * Logic:
   * 1. Uses the great circle initial bearing formula
   * 2. Formula: θ = atan2(sin(Δlong)cos(lat2), cos(lat1)sin(lat2) - sin(lat1)cos(lat2)cos(Δlong))
   * 3. Converts result to degrees and normalizes to 0-360 range
   *
   * Note: The bearing may change as you follow this path due to Earth's curvature.
   *
   * @param {Object} point1 - {latitude, longitude} Starting point
   * @param {Object} point2 - {latitude, longitude} Ending point
   * @returns {number} Bearing in degrees (0-360), rounded to nearest degree
   */
  static calculateBearing(point1, point2) {
    const lat1 = this.toRadians(point1.latitude);
    const lat2 = this.toRadians(point2.latitude);
    const deltaLong = this.toRadians(point2.longitude - point1.longitude);

    const y = Math.sin(deltaLong) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(deltaLong);

    let bearing = Math.atan2(y, x) * 180 / Math.PI;
    bearing = (bearing + 360) % 360;

    return Math.round(bearing);
  }
}

export default GeoHelper;
