import React, { useContext, useEffect, useState, useMemo } from 'react';
import { MissionsContext } from "../../pages/Missions/Missions";
import MissionLoader from "./MissionLoader";
import MissionCard from "./MissionCard";
import OrderByFilter from "./MissionOrderByFilter";
import SearchBar from "./MissionSearchBar";
import MissionScrollView from "./MissionScrollView";
import MissionButton from "./MissionButton";
import svg from '../../assets/images/drone.svg';
import GenericService from "../../services/generic.service";
import { useGlobalModal } from "../../components/Modal/GlobalModalContext";
import ConfirmationModal from "../Modal/ConfirmationModal";

const MissionOverview = ({ onCreateClick, onEditClick }) => {
  const { setAllMissions, allMissions, draftMission, updateDraftMission, setSelectedMissionId, setSnakePathWaypoints, setComplexityLevel } = useContext(MissionsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [orderBy, setOrderBy] = useState('Newest');
  const { showGlobalModal, hideGlobalModal } = useGlobalModal();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [missionToDelete, setMissionToDelete] = useState(null);

  useEffect(() => {
  }, [allMissions]);

  useEffect(() => {
    fetchMissions();
  }, []);

  const fetchMissions = () => {
    try {
      GenericService.MissionService.getMissions().then((response) => {
        setAllMissions(response);
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Failed to fetch missions:', error);
      setIsLoading(false);
    }
  };

  const handleDeleteMission = async (missionId) => {
    // Find the mission to delete
    const missionToDelete = allMissions.find(mission => mission.missionId === missionId);
    if (missionToDelete) {
      setMissionToDelete(missionToDelete);
      setIsDeleteModalOpen(true);
    }
  };

  const confirmDelete = async () => {
    if (!missionToDelete) return;
    
    try {
      await GenericService.MissionService.deleteMission(missionToDelete.missionId);
      window.toast.success('Mission deleted successfully');
      // Refresh the missions list
      fetchMissions();
    } catch (error) {
      console.error('Failed to delete mission:', error);
      window.toast.error('Failed to delete mission');
    } finally {
      // Close the modal and reset the mission to delete
      setIsDeleteModalOpen(false);
      setMissionToDelete(null);
    }
  };

  const cancelDelete = () => {
    setIsDeleteModalOpen(false);
    setMissionToDelete(null);
  };

  const handleMissionClick = (missionId) => {
    // Find the mission by ID
    const selectedMission = allMissions.find(mission => mission.missionId === missionId);

    // If mission is not found, log an error and return
    if (!selectedMission) {
      console.error('Mission not found:', missionId);
      return
    }

    setSelectedMissionId(missionId);

    const filteredWaypoints = selectedMission.waypoints.map(({ waypointId, latitude, longitude, altitude, heading, waypointType, order }) => ({
      waypointId,
      latitude,
      longitude,
      altitude,
      heading,
      waypointType,
      order
    })).filter(waypoint => waypoint.waypointType === 1);


    const homeWaypoint = selectedMission.waypoints && selectedMission.waypoints.length > 0
      ? selectedMission.waypoints[0]
      : null;

    const qualityLevelIndex = selectedMission.flightPreset.quality;
    const quality = { "low": 2, "medium": 1, "high": 0 };

    updateDraftMission({
      ...selectedMission,
      waypoints: filteredWaypoints,
      homeWaypoint: homeWaypoint,
      qualitySettings: {
        level: qualityLevelIndex
      },
      missionSettings: {
        objectOfInterestHeight: selectedMission.flightPreset.objectOfInterestHeight
      },
      advancedSettings: {
        ...selectedMission.flightPreset,
      }
    });

    setComplexityLevel(Object.keys(quality).find(
      (key) => quality[key] === qualityLevelIndex
    ));

    setSnakePathWaypoints(selectedMission.waypoints.filter(waypoint => waypoint.waypointType === 2));

    onEditClick(selectedMission);
  };

  const filteredAndSortedMissions = useMemo(() => {
    let filtered = [...allMissions];

    if (searchValue.trim()) {
      const searchTerm = searchValue.toLowerCase().trim();
      filtered = filtered.filter(mission =>
        mission.name.toLowerCase().includes(searchTerm)
      );
    }

    filtered.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      switch (orderBy) {
        case 'Newest':
          return dateB - dateA;
        case 'Oldest':
          return dateA - dateB;
        default:
          return 0;
      }
    });

    return filtered;
  }, [allMissions, searchValue, orderBy]);

  if (isLoading) {
    return <MissionLoader isLoading={isLoading} />;
  }

  if (allMissions.length === 0) {
    return (
      <>
        <div className="flex flex-col items-center justify-center h-full">
          <div className="flex flex-col items-center gap-6">
            <div className="w-130 h-130 bg-gray-100 rounded-full flex items-center justify-center">
              <img src={svg} alt="drone" className="w-130 h-130" />
            </div>
            <h3 className="text-3xl font-600 text-fontTitle font-inter">No missions yet</h3>
            <p className="text-fontText font-medium text-center font-inter">
              You do not have any missions yet. Create a new mission!
            </p>
            <MissionButton
              className="h-10 text-white font-inter"
              text="+ Create Mission"
              onClick={onCreateClick}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="flex flex-col h-screen w-full relative">
      <div className="flex-none">
        <SearchBar
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search missions..."
        />
        <div className="flex justify-end my-4">
          <OrderByFilter value={orderBy} onChange={setOrderBy} />
        </div>
      </div>

      <div className="h-[70vh] flex flex-col">
        <MissionScrollView>
          {filteredAndSortedMissions.length > 0 ? (
            filteredAndSortedMissions.map((mission) => (
              <MissionCard
                key={mission.id}
                mission={mission}
                onClick={() => handleMissionClick(mission.missionId)}
                onDelete={handleDeleteMission}
              />
            ))
          ) : (
            <div className="flex items-center justify-center h-32 text-gray-500 font-inter">
              No missions found
            </div>
          )}
        </MissionScrollView>
      </div>

      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        title="Confirm Deletion"
        message={missionToDelete ? `Are you sure you want to delete "${missionToDelete.name}"? This action cannot be undone.` : "Are you sure you want to delete this mission?"}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
    </div>
  );
};

export default MissionOverview;
