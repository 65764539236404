import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import MapStyleSwitch from '../../../components/MapStyleSwitch/MapStyleSwitch';
import { DashboardContext } from '../Dashboard';
import DroneBar from './DroneBar/DroneBar';
import DataBar from './DataBar/DataBar';
import WebRTC from '../../../components/Video/WebRTC';

export default function MapOverlay({ drones }) {
  const { mapStyle, setMapStyle, selectedDrone, setSelectedDrone } = useContext(DashboardContext);

  const headingClickedHandler = () => {
    if (selectedDrone !== null) {
      setSelectedDrone(null);
    }
  };

  return (
    <>
      {/* Left overlay content */}
      <div className="map-overlay-left">
        <div>
          <span
            className="heading cursor-pointer"
            onClick={headingClickedHandler}
            onKeyDown={headingClickedHandler}
            role="presentation"
          >
            {selectedDrone !== null && <FontAwesomeIcon icon={regular('chevron-left')} />}
            <h2 style={{ color: mapStyle === 'satellite-v9' ? 'white' : 'black' }} className="ml-3">
              {selectedDrone !== null ? selectedDrone.name : 'Dashboard'}
            </h2>
          </span>

          {selectedDrone !== null && <DataBar selectedDrone={selectedDrone} />}
        </div>
      </div>

      {/* Bottom drone bar */}
      {selectedDrone === null && (
        <div className="fixed bottom-0">
          <DroneBar drones={drones} />
        </div>
      )}

      {/* Map style switcher */}
      <MapStyleSwitch setMapStyle={setMapStyle} />

      {/* Video player positioned top-right */}
      {selectedDrone && (
        <div
          className="
        fixed
        top-16
        right-4
        z-50
        w-80 h-48            /* Base size (very small screens) */
        sm:w-80 sm:h-48      /* Small devices (640px+) */
        md:w-80 md:h-48      /* Medium devices (768px+) */
        lg:w-80 lg:h-48      /* Large devices (1024px+) */
        xl:w-96 xl:h-56      /* Extra-large devices (1280px+) */
        overflow-hidden
        rounded-lg
        shadow-xl
      "
        >
          <WebRTC selectedDrone={selectedDrone} />
        </div>
      )}
    </>
  );
}
