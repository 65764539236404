import React from 'react';
import { motion } from 'framer-motion';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { x: -20, opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      damping: 25,
      stiffness: 300
    }
  }
};

const MissionScrollView = ({ children }) => {
  const handleScroll = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="flex-2 overflow-hidden relative">
      <motion.div
        className="h-full overflow-y-auto pr-2"
        style={{
          '--scrollbar-width': '4px',
          '--scrollbar-track': 'transparent',
          '--scrollbar-thumb': '#4138c2',
          scrollbarWidth: 'thin',
          scrollbarColor: '#3933ec transparent',
          '&::-webkit-scrollbar': {
            width: 'var(--scrollbar-width)',
          },
          '&::-webkit-scrollbar-track': {
            background: 'var(--scrollbar-track)',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'linear-gradient(to bottom, rgba(65, 56, 194, 0.8), #4138c2 10%, #4138c2 90%, rgba(65, 56, 194, 0.8))',
            borderRadius: '20px',
          },
        }}
        onWheel={handleScroll}
      >
        <motion.div
          className="flex flex-col gap-3 pb-6"
          variants={container}
          initial="hidden"
          animate="show"
        >
          {React.Children.map(children, (child, index) => (
            child ? (
              <motion.div
                key={index}
                variants={item}
              >
                {child}
              </motion.div>
            ) : child
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default MissionScrollView;
