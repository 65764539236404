import React, { useContext, useEffect, useState } from 'react';
import './Weather.scss';
import axios from 'axios';
import WeatherIcon from './WeatherIcon';
import { SideNavContext } from '../SideNav';

// SVG Icons
const SvgIcons = {
  Loader: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={props.className || ''}>
      <path d="M21 12a9 9 0 1 1-6.219-8.56"></path>
    </svg>
  ),
  ThermometerSun: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M12 9a4 4 0 0 0-2 7.5"></path>
      <path d="M12 3v2"></path>
      <path d="m6.6 18.4-1.4 1.4"></path>
      <path d="M20 4v10.54a4 4 0 1 1-4 0V4a2 2 0 0 1 4 0Z"></path>
      <path d="M4 13H2"></path>
      <path d="M6.34 7.34 4.93 5.93"></path>
    </svg>
  ),
  Wind: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M17.7 7.7a2.5 2.5 0 1 1 1.8 4.3H2"></path>
      <path d="M9.6 4.6A2 2 0 1 1 11 8H2"></path>
      <path d="M12.6 19.4A2 2 0 1 0 14 16H2"></path>
    </svg>
  ),
  Droplets: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M7 16.3c2.2 0 4-1.83 4-4.05 0-1.16-.57-2.26-1.71-3.19S7.29 6.75 7 5.3c-.29 1.45-1.14 2.84-2.29 3.76S3 11.1 3 12.25c0 2.22 1.8 4.05 4 4.05z"></path>
      <path d="M12.56 6.6A10.97 10.97 0 0 0 14 3.02c.5 2.5 2 4.9 4 6.5s3 3.5 3 5.5a6.98 6.98 0 0 1-11.91 4.97"></path>
    </svg>
  ),
  AlertTriangle: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={props.className || ''}>
      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path>
      <path d="M12 9v4"></path>
      <path d="M12 17h.01"></path>
    </svg>
  ),
  RefreshCw: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={props.className || ''}>
      <path d="M3 2v6h6"></path>
      <path d="M21 12A9 9 0 0 0 6 5.3L3 8"></path>
      <path d="M21 22v-6h-6"></path>
      <path d="M3 12a9 9 0 0 0 15 6.7l3-2.7"></path>
    </svg>
  )
};

/**
 *
 * This is the Weather API (https://openweathermap.org/api) that is used to display the current weather in the bottom
 * of the SideNav and is also used to perform External checks for the pre-launch of a drone mission
 *
 */

/**
 * Fetches weather information from OpenWeather API
 * @param {Object} coords - Coordinates object with latitude and longitude
 * @returns {Promise} API response with weather data
 */
export async function getWeatherInfo(coords) {
  try {
    const response = await axios.get(
      `https://api.openweathermap.org/data/2.5/weather?lat=${coords.latitude}&lon=${coords.longitude}&units=metric&lang=en&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}`
    );
    return response;
  } catch (error) {
    console.error('Error fetching weather data:', error);
    return null;
  }
}

export default function Weather() {
  const { 
    weatherTooltipOpen, 
    setWeatherTooltipOpen, 
    weatherInfo,
    isLoadingWeather,
    weatherError,
    refreshWeather
  } = useContext(SideNavContext);

  // Close the tooltip when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (weatherTooltipOpen && !event.target.closest('.Weather')) {
        setWeatherTooltipOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [weatherTooltipOpen, setWeatherTooltipOpen]);

  return (
    <div className="Weather">
      <div className="weather-icon-wrapper">
        <WeatherIcon 
          icon={weatherInfo?.weather && weatherInfo?.weather[0]?.icon} 
          clickEnabled={true} 
        />
      </div>
      
      {weatherTooltipOpen && (
        <div className="weather-popup">
          <WeatherTooltip 
            weatherInfo={weatherInfo} 
            isLoading={isLoadingWeather} 
            error={weatherError}
            onRetry={refreshWeather}
          />
        </div>
      )}
    </div>
  );
}

function WeatherTooltip({ weatherInfo, isLoading, error, onRetry }) {
  if (isLoading) {
    return (
      <div className="weather-tooltip-content loading">
        <div className="weather-tooltip-header">
          <h3>Weather</h3>
        </div>
        <div className="loading-container">
          <SvgIcons.Loader className="weather-loader" size={24} />
          <p>Loading weather data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="weather-tooltip-content error">
        <div className="weather-tooltip-header">
          <h3>Weather</h3>
          <SvgIcons.AlertTriangle className="error-icon" size={18} />
        </div>
        <p>Unable to load weather information</p>
        <div className="error-details">
          <p className="error-message">{error}</p>
          <p className="error-hint">
            {error.includes('denied') ? 
              'Please allow location access in your browser settings.' : 
              error.includes('unavailable') ? 
                'Please check your device location settings or try again later.' : 
                'Please check your location settings'}
          </p>
        </div>
        <button className="retry-button" onClick={onRetry}>
          <SvgIcons.RefreshCw size={14} />
          <span>Retry</span>
        </button>
      </div>
    );
  }

  // If weatherInfo exists but has placeholder values (fallback data)
  if (weatherInfo && weatherInfo.main.temp === '--') {
    return (
      <div className="weather-tooltip-content limited">
        <div className="weather-tooltip-header">
          <h3>Weather</h3>
          <p className="location">Location unavailable</p>
        </div>
        <div className="limited-container">
          <SvgIcons.AlertTriangle size={24} className="warning-icon" />
          <p>Weather data temporarily unavailable</p>
          <p className="retry-hint">Please check your location settings and try again later</p>
        </div>
        <button className="retry-button" onClick={onRetry}>
          <SvgIcons.RefreshCw size={14} />
          <span>Retry</span>
        </button>
      </div>
    );
  }

  if (!weatherInfo || !weatherInfo.weather) {
    return (
      <div className="weather-tooltip-content error">
        <div className="weather-tooltip-header">
          <h3>Weather</h3>
          <SvgIcons.AlertTriangle className="error-icon" size={18} />
        </div>
        <p>Unable to load weather information</p>
        <p className="error-message">Please check your location settings</p>
        <button className="retry-button" onClick={onRetry}>
          <SvgIcons.RefreshCw size={14} />
          <span>Retry</span>
        </button>
      </div>
    );
  }

  const getTemperatureColor = (temp) => {
    if (temp === '--') return 'text-gray-500';
    if (temp < 0) return 'text-blue-500';
    if (temp < 10) return 'text-cyan-500';
    if (temp < 20) return 'text-green-500';
    if (temp < 30) return 'text-yellow-500';
    return 'text-red-500';
  };

  return (
    <div className="weather-tooltip-content">
      <div className="weather-tooltip-header">
        <h3>Weather</h3>
        <p className="location">{weatherInfo.name}</p>
      </div>

      <div className="weather-main-info">
        <div className="weather-icon-large">
          <WeatherIcon icon={weatherInfo.weather[0].icon} clickEnabled={false} />
          <p className="weather-description">{weatherInfo.weather[0].description}</p>
        </div>
        
        <div className="temperature">
          <SvgIcons.ThermometerSun size={16} />
          <span className={`temp-value ${getTemperatureColor(weatherInfo.main.temp)}`}>
            {Math.round(weatherInfo.main.temp * 10) / 10}°C
          </span>
        </div>
      </div>
      
      <div className="weather-details">
        <div className="weather-detail-item">
          <SvgIcons.Wind size={14} />
          <p>Wind: <span>{Math.round(weatherInfo.wind.speed * 3.6 * 10) / 10} km/h</span></p>
        </div>
        
        <div className="weather-detail-item">
          <SvgIcons.Droplets size={14} />
          <p>Humidity: <span>{weatherInfo.main.humidity}%</span></p>
        </div>
      </div>
      
      <div className="weather-footer">
        <p className="weather-updated">
          Updated: {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </p>
      </div>
    </div>
  );
}
