/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef } from 'react';

export default function WebRTC({ selectedDrone }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (selectedDrone) {
      // Build the drone video URL from your environment variable
      const src = `${process.env.REACT_APP_STREAM_URL}/${selectedDrone.droneId}`;
      // Set the iframe source
      videoRef.current.src = src;
      // Make sure it's visible and fills parent
      videoRef.current.className = 'w-full h-full block';
    } else {
      // Hide and clear when no drone is selected
      videoRef.current.className = 'hidden';
      videoRef.current.src = null;
    }
  }, [selectedDrone]);

  return (
    <iframe
      id="video"
      title="Livestream"
      ref={videoRef}
      src=""
      className="hidden" // Hidden until we set it in useEffect
    />
  );
}
