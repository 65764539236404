import { motion } from 'framer-motion';

const MissionButton = ({
                         text,
                         onClick,
                         fontSize = 'text-base',
                         radius = 'rounded-lg',
                         className = '',
                         variant = 'primary'
                       }) => {
  const baseClasses = 'px-6 py-2 flex items-center justify-center gap-2 transition-all font-bold';

  const variants = {
    primary: 'bg-[#4F46E5] hover:bg-[#4338CA] text-white',
    secondary: 'bg-[#eeeeee] hover:bg-[#e5e5e5] text-gray-900'
  };

  return (
    <motion.button
      onClick={onClick}
      whileTap={{ scale: 0.98 }}
      className={`
        ${baseClasses}
        ${variants[variant]}
        ${fontSize}
        ${radius}
        ${className}
      `}
    >
      {text}
    </motion.button>
  );
};

export default MissionButton;
