import React from 'react';
import { motion, useInView } from 'framer-motion';
import { Trash2 } from "lucide-react";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffTime = Math.abs(now - date);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
  // If less than 24 hours ago, show relative time
  if (diffDays === 0) {
    const hours = Math.floor(diffTime / (1000 * 60 * 60));
    if (hours === 0) {
      const minutes = Math.floor(diffTime / (1000 * 60));
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    }
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  }
  
  // If less than 7 days ago, show days ago
  if (diffDays < 7) {
    return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
  }
  
  // Otherwise show formatted date
  const options = { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric',
    hour: '2-digit', 
    minute: '2-digit'
  };
  return date.toLocaleDateString('en-US', options);
};

const MissionCard = ({ mission, onClick, onDelete }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, {
    once: false,
    amount: 0.5
  });

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(mission.missionId);
  };

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 10 }}
      animate={{
        opacity: isInView ? 1 : 0,
        y: isInView ? 0 : 10
      }}
      transition={{ duration: 0.2 }}
      whileHover={{
        backgroundColor: "#f0f3fc",
        scale: 1.005,
        transition: { duration: 0.15 }
      }}
      className="bg-[#f9fafe] p-4 rounded-lg cursor-pointer w-full"
      onClick={onClick}
    >
      <div className="flex items-center justify-between">
        <div>
          <motion.h3
            whileHover={{ opacity: 0.8 }}
            className="text-[#070e17] text-lg font-semibold font-inter mb-1"
          >
            {mission.name}
          </motion.h3>
          <div className="font-inter flex items-center gap-1">
            <motion.span
              whileHover={{ opacity: 0.8 }}
              className="text-[#686868] text-sm font-normal"
            >
              Created
            </motion.span>
            <motion.p
              whileHover={{ opacity: 0.8 }}
              className="text-[#7B8B9D] text-sm font-medium"
            >
              {formatDate(mission.createdAt)}
            </motion.p>
          </div>
        </div>
        <div className="flex items-center">
          <motion.button
            onClick={handleDelete}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="p-2 rounded-full hover:bg-red-50"
          >
            <Trash2 className="w-5 h-5 text-red-500" />
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};

export default MissionCard;
