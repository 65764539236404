import React, { useContext, useState, useEffect } from 'react';
import './NotificationsBell.scss';
import { SideNavContext } from '../SideNav';

// SVG Icons
const SvgIcons = {
  Bell: (props) => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={props.size || 20} 
      height={props.size || 20} 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      className={props.className || ''}
    >
      <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"></path>
      <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"></path>
    </svg>
  ),
  Menu: (props) => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={props.size || 20} 
      height={props.size || 20} 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      className={props.className || ''}
    >
      <line x1="3" y1="6" x2="21" y2="6"></line>
      <line x1="3" y1="12" x2="21" y2="12"></line>
      <line x1="3" y1="18" x2="21" y2="18"></line>
    </svg>
  ),
  CheckCircle: (props) => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={props.size || 20} 
      height={props.size || 20} 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      className={props.className || ''}
    >
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
      <polyline points="22 4 12 14.01 9 11.01"></polyline>
    </svg>
  ),
  AlertCircle: (props) => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={props.size || 20} 
      height={props.size || 20} 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      className={props.className || ''}
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="8" x2="12" y2="12"></line>
      <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </svg>
  ),
  XCircle: (props) => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={props.size || 20} 
      height={props.size || 20} 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      className={props.className || ''}
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="15" y1="9" x2="9" y2="15"></line>
      <line x1="9" y1="9" x2="15" y2="15"></line>
    </svg>
  ),
  X: (props) => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={props.size || 20} 
      height={props.size || 20} 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      className={props.className || ''}
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  )
};

// Notification Component
function Notification({ messageType, messageTitle, message, firstChild, onDismiss }) {
  const getNotificationIcon = () => {
    switch (messageType) {
      case 'check':
        return <SvgIcons.CheckCircle size={16} className="icon-check" />;
      case 'warning':
        return <SvgIcons.AlertCircle size={16} className="icon-warning" />;
      case 'error':
        return <SvgIcons.XCircle size={16} className="icon-error" />;
      default:
        return <SvgIcons.CheckCircle size={16} className="icon-check" />;
    }
  };

  return (
    <div className={`notification-item ${firstChild ? 'first-child' : ''}`}>
      <div className="notification-content">
        <div className="notification-title">
          {getNotificationIcon()}
          <h4>{messageTitle}</h4>
        </div>
        <p className="notification-message">{message}</p>
      </div>
      <div className="notification-close" onClick={onDismiss}>
        <SvgIcons.X size={14} />
      </div>
    </div>
  );
}

export default function NotificationsBell() {
  const { notificationsTooltipOpen, setNotificationsTooltipOpen, setWeatherTooltipOpen } = useContext(SideNavContext);
  
  // Initialize with empty notifications array
  const [notifications, setNotifications] = useState([]);

  const handleDismissNotification = (id) => {
    setNotifications(notifications.filter(notification => notification.id !== id));
  };

  const handleClearAllNotifications = () => {
    setNotifications([]);
  };

  const handleBellClick = () => {
    setNotificationsTooltipOpen(!notificationsTooltipOpen);

    if (!notificationsTooltipOpen) {
      setWeatherTooltipOpen(false);
    }
  };

  // Close the popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationsTooltipOpen && !event.target.closest('.NotificationsBell')) {
        setNotificationsTooltipOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [notificationsTooltipOpen, setNotificationsTooltipOpen]);

  return (
    <div className="NotificationsBell">
      <div className="bell-icon-wrapper" onClick={handleBellClick}>
        <SvgIcons.Bell size={20} />
        {notifications.length > 0 && (
          <span className="notification-badge">{notifications.length}</span>
        )}
      </div>
      
      {notificationsTooltipOpen && (
        <div className="notifications-popup">
          <div className="notifications-header">
            <h3>Notifications</h3>
            <div className="clear-all-button" onClick={handleClearAllNotifications}>
              <SvgIcons.Menu size={16} />
            </div>
          </div>
          
          <div className="notifications-list">
            {notifications.length === 0 ? (
              <div className="empty-notifications">
                <p>No notifications</p>
              </div>
            ) : (
              notifications.map((notification, index) => (
                <Notification
                  key={notification.id}
                  messageTitle={notification.messageTitle}
                  message={notification.message}
                  messageType={notification.messageType}
                  firstChild={index === 0}
                  onDismiss={() => handleDismissNotification(notification.id)}
                />
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}
