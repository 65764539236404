import React, { useContext, useEffect, useState } from 'react';
import { MissionsContext } from '../Missions';
import MissionLayout from "../../../components/Mission/MissionLayout";
import { useGlobalModal } from "../../../components/Modal/GlobalModalContext";
import MapStyleSwitch from '../../../components/MapStyleSwitch/MapStyleSwitch';
import MissionOverview from '../../../components/Mission/MissionOverview';
import MissionCreateOrUpdate from '../../../components/Mission/MissionCreateOrUpdate';

export default function MapOverlay() {
  const {
    showReportLog,
    setMapStyle,
    resetDraftMission,
    draftMission
  } = useContext(MissionsContext);
  const { showGlobalModal, hideGlobalModal } = useGlobalModal();
  const [showCreateOrUpdateMission, setShowCreateOrUpdateMission] = useState(false);
  const [selectedMissionName, setSelectedMissionName] = useState(null)

  useEffect(() => {
    resetDraftMission();
  }, []);

  useEffect(() => {
    setSelectedMissionName(draftMission.name)
  }, [draftMission])

  const handleEditClick = (selectedMission) => {
    setShowCreateOrUpdateMission(true);
  };

  const handleCreateNewMission = () => {
    setShowCreateOrUpdateMission(true);
    setSelectedMissionName(null);
    resetDraftMission();
  }

  const handleOnReturnToOverview = () => {
    setShowCreateOrUpdateMission(false);
    resetDraftMission();
  }

  const renderContent = () => {
    if (showCreateOrUpdateMission) {
      return (
        <MissionCreateOrUpdate
          onBack={handleOnReturnToOverview}
        />
      );
    } else {
      return (
        <MissionOverview
          onCreateClick={handleCreateNewMission}
          onEditClick={handleEditClick}
        />
      );
    }
  };

  return (
    <div className={`${showReportLog ? 'pointer-events-none' : ''}`}>
      <MissionLayout
        showNewButton={!showCreateOrUpdateMission}
        onNewMissionClick={handleCreateNewMission}
        showBack={showCreateOrUpdateMission}
        onBack={handleOnReturnToOverview}
        title={showCreateOrUpdateMission ? (selectedMissionName ? `"${selectedMissionName}"` : 'New mission') : 'Missions'}
      >
        {renderContent()}
      </MissionLayout>
      <MapStyleSwitch setMapStyle={setMapStyle} />
    </div>
  );
}
