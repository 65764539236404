import React from 'react';
import './Button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

/**
 *
 * Custom button that can be modify to be used for more than just
 * a cancel or start button
 *
 */

export default function Button({ text, onClick, buttonType, isDisabled, optionalClass }) {
  const buttonStyle = `submitBtn px-6 py-2 flex items-center justify-center transition-all font-bold ${optionalClass} ${isDisabled ? 'disabled' : ''}`;

  // Function to define the btn icon (extend for other functionality)
  const renderIcon = () => {
    switch (buttonType) {
      case 'cancel':
      case 'cancelOnly':
        return (
          <span className="iconCancel">
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
        );
      case 'start':
        return (
          <span className="iconStart">
            <FontAwesomeIcon icon={faRocket} />
          </span>
        );
      case 'start-admin':
        return (
          <span className="iconStart">
            <FontAwesomeIcon icon={faRocket} />
          </span>
        );
      case 'schedule':
        return (
          <span className="iconStartMission">
            <FontAwesomeIcon icon={faRocket} />
          </span>
        );
      case 'loading':
        return (
          <span className="iconLoad">
            <FontAwesomeIcon size="1x" spin={true} icon={faSpinnerThird} />
          </span>
        )
      default:
        return null;
    }
  };

  return (
    <button className={buttonStyle} onClick={onClick} type="submit" disabled={isDisabled}>
      {buttonType === "loading" && (
        <span >
         {renderIcon()}
        </span>
      )}
      {buttonType !== "loading" && (
        <span>
          {text} {renderIcon()}
        </span>
      )}
    </button>
  );
}
