import React, { useState, useEffect } from 'react';
import './SideNav.scss';
import { useAuth } from 'react-oidc-context';

import { Link, useLocation } from 'react-router-dom';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';

// Import components
import NavItem from './NavItem/NavItem';
import Clock from './Clock/Clock';
import NotificationsBell from './NotificationsBell/NotificationsBell';
import Weather, { getWeatherInfo } from './Weather/Weather';
import logo from '../../../assets/images/logo.png';
import Logout from './Logout/Logout';
import UserService from '../../../services/Authentication/user.service';

// Create and export context
export const SideNavContext = React.createContext(undefined);

export default function SideNav() {
  const location = useLocation();
  const isAdmin = UserService.checkAdmin(useAuth().user);

  const [weatherTooltipOpen, setWeatherTooltipOpen] = useState(false);
  const [notificationsTooltipOpen, setNotificationsTooltipOpen] = useState(false);
  const [weatherInfo, setWeatherInfo] = useState(null);
  const [isLoadingWeather, setIsLoadingWeather] = useState(true);
  const [weatherError, setWeatherError] = useState(null);

  // Function to manually refresh weather data
  const refreshWeather = () => {
    setIsLoadingWeather(true);
    fetchWeatherData();
  };

  // Fetch weather information when component mounts
  const fetchWeatherData = async () => {
    try {
      setIsLoadingWeather(true);
      setWeatherError(null);
      
      if (!navigator.geolocation) {
        throw new Error('Geolocation is not supported by your browser');
      }
      
      // Get current position with a promise to handle errors better
      const getCurrentPosition = () => {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            resolve, 
            (error) => {
              // Handle specific geolocation errors
              if (error.code === 1) {
                reject(new Error('Location access denied. Please enable location access in your browser settings.'));
              } else if (error.code === 2) {
                reject(new Error('Location unavailable. Please try again later or check your device\'s location settings.'));
              } else if (error.code === 3) {
                reject(new Error('Location request timed out. Please try again.'));
              } else {
                reject(error);
              }
            },
            {
              timeout: 10000,
              maximumAge: 60000,
              enableHighAccuracy: false
            }
          );
        });
      };
      
      // Get position and fetch weather data
      const position = await getCurrentPosition();
      const response = await getWeatherInfo(position.coords);
      
      if (response && response.data) {
        setWeatherInfo(response.data);
      } else {
        throw new Error('Failed to fetch weather data');
      }
    } catch (error) {
      console.error('Weather error:', error);
      setWeatherError(error.message || 'Error fetching weather data');
      
      // Set default weather info for a fallback display
      setWeatherInfo({
        weather: [{ 
          icon: '01d', 
          description: 'weather unavailable' 
        }],
        main: { 
          temp: '--',
          humidity: '--'
        },
        wind: { 
          speed: '--' 
        },
        name: 'Location unavailable'
      });
    } finally {
      setIsLoadingWeather(false);
    }
  };

  useEffect(() => {
    // Fetch weather information on component mount
    fetchWeatherData();
    
    // Set up a timer to refresh weather data every 30 minutes
    const weatherRefreshInterval = setInterval(() => {
      fetchWeatherData();
    }, 30 * 60 * 1000);
    
    // Clean up interval on component unmount
    return () => clearInterval(weatherRefreshInterval);
  }, []);

  return (
    <SideNavContext.Provider
      value={{
        weatherTooltipOpen,
        setWeatherTooltipOpen,
        notificationsTooltipOpen,
        setNotificationsTooltipOpen,
        weatherInfo,
        setWeatherInfo,
        isLoadingWeather,
        weatherError,
        refreshWeather
      }}
    >
      <div className="SideNav overflow-y-auto">
        <div className="top">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="middle">
          <NavItem link="/">
            {location.pathname === '/' ? (
              <FontAwesomeIcon icon={solid('chart-tree-map')} />
            ) : (
              <FontAwesomeIcon icon={regular('chart-tree-map')} />
            )}
          </NavItem>

          <NavItem link="/missions">
            {location.pathname === '/missions' ? (
              <FontAwesomeIcon icon={solid('bullseye-arrow')} />
            ) : (
              <FontAwesomeIcon icon={regular('bullseye-arrow')} />
            )}
          </NavItem>

          <NavItem link="/schedule">
            {location.pathname === '/schedule' ? (
              <FontAwesomeIcon icon={solid('calendar-days')} />
            ) : (
              <FontAwesomeIcon icon={regular('calendar-days')} />
            )}
          </NavItem>

          <NavItem link="/data">
            {location.pathname === '/data' ? (
              <FontAwesomeIcon icon={solid('chart-line')} />
            ) : (
              <FontAwesomeIcon icon={regular('chart-line')} />
            )}
          </NavItem>

          <NavItem link="/AppStore">
            {location.pathname === '/AppStore' ? (
              <FontAwesomeIcon icon={solid('grip')} />
            ) : (
              <FontAwesomeIcon icon={regular('grip')} />
            )}
          </NavItem>

          {/* Only show this if the user has the Admin role */}
          {isAdmin ? (
            <NavItem link="/users">
              {location.pathname === '/users' ? (
                <FontAwesomeIcon icon={solid('user')} />
              ) : (
                <FontAwesomeIcon icon={regular('user')} />
              )}
            </NavItem>
          ) : null}

          {/* Only show this if the user has the Admin role */}
          {isAdmin ? (
            <NavItem link="/testpage">
              {location.pathname === '/testpage' ? (
                <FontAwesomeIcon icon={solid('cog')} />
              ) : (
                <FontAwesomeIcon icon={regular('cog')} />
              )}
            </NavItem>
          ) : null}
        </div>
        <div className="bottom">
          <Weather />
          <NotificationsBell />
          <Logout />
          <Clock />
        </div>
      </div>
    </SideNavContext.Provider>
  );
}
